import React from 'react'

const Nosotros = () => {
	document.title = "Sobre nosotros - Bal Solutions";

	const renderBanner = () => {
		return (
			<section className="banner nosotros" >

				<div className="px-0 container-fluid">

					<div className="row">

						<div className="content col-12">

							<h1 style={{ fontWeight: "900" }}>
								Sobre Nosotros
							</h1>

						</div>

					</div>

				</div>

			</section>
		)
	}

	const renderCards = () => {
		return (
			<section className="about-us about-us-cards">

				<div className="container py-5">

					<div className="row justify-content-center">

						<div className="cards mb-4 col-12 col-md-3">

							<div className="card">

								<div className="card-header bg-white border-0">

									<svg xmlns="http://www.w3.org/2000/svg" width="125" height="125" viewBox="0 0 125 125">   <g id="Group_69" data-name="Group 69" transform="translate(-1425 -468)">     <circle id="Ellipse_4" data-name="Ellipse 4" cx="62.5" cy="62.5" r="62.5" transform="translate(1425 468)" fill="#fb698b"></circle>     <path id="hand-holding-heart-solid" d="M19.139,21.771a9.008,9.008,0,0,1,0-12.611,8.7,8.7,0,0,1,12.424,0l2.067,2.1L35.7,9.16a8.7,8.7,0,0,1,12.424,0,9.008,9.008,0,0,1,0,12.611L36.257,33.822a3.693,3.693,0,0,1-5.255,0Zm47.21,23.284a4.666,4.666,0,0,1-.993,6.527L50.573,62.477a15.9,15.9,0,0,1-9.423,3.094H3.737A3.733,3.733,0,0,1,0,61.835V54.361a3.733,3.733,0,0,1,3.737-3.737h4.3l5.243-4.2a14.916,14.916,0,0,1,9.342-3.27H41.1a3.737,3.737,0,1,1,0,7.473H31.761a1.868,1.868,0,0,0,0,3.737H45.844l13.977-10.3A4.666,4.666,0,0,1,66.349,45.055Zm-43.742,5.57Z" transform="translate(1454 494.45)" fill="#fff"></path>   </g> </svg>

								</div>

								<div className="card-body">

									<h5 className="title">
										Integridad
									</h5>

									Operamos con transparencia y ética en todas nuestras interacciones con clientes, colaboradores y socios comerciales.

								</div>

							</div>

						</div>

						<div className="cards mb-4 col-12 col-md-3">

							<div className="card">

								<div className="card-header bg-white border-0">

									<svg xmlns="http://www.w3.org/2000/svg" width="125" height="125" viewBox="0 0 125 125">   <g id="Group_80" data-name="Group 80" transform="translate(6142 -3843)">     <circle id="Ellipse_2" data-name="Ellipse 2" cx="62.5" cy="62.5" r="62.5" transform="translate(-6142 3843)" fill="#309b90"></circle>     <path id="brain-solid" d="M23.984,0a7.3,7.3,0,0,1,7.3,7.3V59.438a7.3,7.3,0,0,1-14.56.769,8.186,8.186,0,0,1-2.125.274A8.346,8.346,0,0,1,6.726,49.376,10.427,10.427,0,0,1,5.97,30.2a8.257,8.257,0,0,1-1.8-5.175,8.359,8.359,0,0,1,6.57-8.16,8.147,8.147,0,0,1-.313-2.268,8.363,8.363,0,0,1,6.3-8.095A7.3,7.3,0,0,1,23.984,0Zm18.77,0a7.313,7.313,0,0,1,7.26,6.5,8.337,8.337,0,0,1,6.3,8.095A8.147,8.147,0,0,1,56,16.867a8.348,8.348,0,0,1,6.569,8.16,8.257,8.257,0,0,1-1.8,5.175,10.427,10.427,0,0,1-.756,19.174,8.346,8.346,0,0,1-7.873,11.106,8.186,8.186,0,0,1-2.125-.274,7.3,7.3,0,0,1-14.56-.769V7.3A7.3,7.3,0,0,1,42.754,0Z" transform="translate(-6112.869 3872.131)" fill="#fff"></path>   </g> </svg>

								</div>

								<div className="card-body">

									<h5 className="title">
										Innovación
									</h5>

									Buscamos constantemente nuevas formas de mejorar y optimizar nuestros procesos y servicios para brindar soluciones eficientes a nuestros clientes.

								</div>

							</div>

						</div>

						<div className="cards mb-4 col-12 col-md-3">

							<div className="card">

								<div className="card-header bg-white border-0">

									<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128" color="#6F6FC1"><g id="Grupo_27" data-name="Grupo 27" transform="translate(-370 -468)"><circle id="Elipse_1" data-name="Elipse 1" cx="64" cy="64" r="64" transform="translate(370 468)" fill="currentColor"></circle><path id="handshake-regular" d="M32.652,64.072l-6.13,6.13a12.539,12.539,0,0,0-4.978,2.7l-3.191,2.843a6.71,6.71,0,0,1-4.462,1.691H11.516V92.789A9.6,9.6,0,0,1,18.029,95.6l4.27,4.27.84.84h0l3.239,3.239a1.926,1.926,0,0,0,2.711,0,1.811,1.811,0,0,0,.444-.7,2.889,2.889,0,0,1,2.075-1.835,2.852,2.852,0,0,1,2.663.78l1.3,1.272a3.548,3.548,0,0,0,5.026,0,3.484,3.484,0,0,0,1.032-2.327,2.847,2.847,0,0,1,1.631-2.447,2.882,2.882,0,0,1,2.927.252,2.878,2.878,0,0,0,3.707-4.378L40.8,85.472,36.5,89.431A8.666,8.666,0,0,1,24.675,76.763l8.409-7.941a17.7,17.7,0,0,1,23.9-.36l3.611,3.215H74.852A1.925,1.925,0,0,1,76.772,73.6V98.547a3.834,3.834,0,0,1-3.839,3.839H69.094a3.826,3.826,0,0,1-3.323-1.919H55.587a8.815,8.815,0,0,1-1.619,2.243,8.662,8.662,0,0,1-7.557,2.411,9.161,9.161,0,0,1-1.751,2.423,9.339,9.339,0,0,1-12.044.972,7.671,7.671,0,0,1-10.316-.492l-3.227-3.239-.84-.84-4.27-4.27a3.863,3.863,0,0,0-2.447-1.116,3.84,3.84,0,0,1-3.839,3.827H3.839A3.834,3.834,0,0,1,0,98.547V73.6a1.925,1.925,0,0,1,1.919-1.919H13.891a.93.93,0,0,0,.636-.24l3.179-2.831A18.29,18.29,0,0,1,29.833,64h1.236C31.6,64,32.136,64.024,32.652,64.072Zm32.6,30.637V77.435H59.5a2.846,2.846,0,0,1-1.907-.732l-4.426-3.935a11.948,11.948,0,0,0-16.122.24L28.633,80.95A2.9,2.9,0,0,0,32.592,85.2l8.625-7.965a2.878,2.878,0,1,1,3.9,4.234l-.1.1,8.925,8.925a8.665,8.665,0,0,1,2.327,4.21h8.985ZM7.677,96.628a1.919,1.919,0,1,0-1.919,1.919A1.919,1.919,0,0,0,7.677,96.628Zm63.337,1.919a1.919,1.919,0,1,0-1.919-1.919A1.919,1.919,0,0,0,71.014,98.547Z" transform="translate(396 447.645)" fill="#fff"></path></g></svg>

								</div>

								<div className="card-body">

									<h5 className="title">
										Compromiso
									</h5>

									Estamos dedicados a brindar un servicio pensado por y para los usuarios y a garantizar nuestro máximo esfuerzo en cada trabajo que realizamos.
								</div>

							</div>

						</div>

						<div className="cards mb-4 col-12 col-md-3">

							<div className="card">

								<div className="card-header bg-white border-0">

									<svg xmlns="http://www.w3.org/2000/svg" width="125" height="125" viewBox="0 0 125 125">   <g id="Group_79" data-name="Group 79" transform="translate(6407 -3837)">     <circle id="Ellipse_3" data-name="Ellipse 3" cx="62.5" cy="62.5" r="62.5" transform="translate(-6407 3837)" fill="#ee8e4e"></circle>     <path id="bolt-solid" d="M74.833,6.016a4.323,4.323,0,0,0-6.821-4.957L33.436,31.313a4.324,4.324,0,0,0,2.85,7.577h15.06L40.959,63.121a4.323,4.323,0,0,0,6.821,4.957L82.356,37.823a4.313,4.313,0,0,0-2.85-7.564H64.447Z" transform="translate(-6402.396 3865.825)" fill="#fff"></path>   </g> </svg>

								</div>

								<div className="card-body">

									<h5 className="title">
										Agilidad
									</h5>

									Proporcionar flexibilidad, entrega rápida, colaboración efectiva y un enfoque en la mejora continua.
								</div>

							</div>

						</div>

					</div>

				</div>

			</section>
		)
	}

	const renderDescription = () => {
		return (
			<section className="about-us description">

				<div className="container">

					<div className="row d-flex justify-content-center">

						<div className="col-12 col-md-10 col-lg-8">
							Resolvemos las necesidades integrales de venta, reparación y mantenimiento, enfocándonos en maximizar la satisfacción del cliente mediante soluciones personalizadas, atención rápida y mejoras continuas en la fiabilidad y eficiencia de los equipos humanos e industriales.
						</div>

					</div>

				</div>

			</section>
		)
	}

	const renderMision = () => {
		return (
			<section className="about-us mission-vision">

				<div className="container">

					<div className="row">

						<div className="content col-lg-6 col-md-12">

							<div>
								<svg xmlns="http://www.w3.org/2000/svg" width="34.805" height="34.805" viewBox="0 0 34.805 34.805">
									<path id="Trazado_27" data-name="Trazado 27" d="M44.537,22.828l1.637,9.729,8.081-5.714,2.139,2.152-5.723,8.04,9.75,1.672-.006,3.038L50.685,43.38l5.714,8.08L54.248,53.6l-8.04-5.723-1.672,9.75L41.5,57.621l-1.637-9.73-8.08,5.715-2.14-2.152,5.723-8.04-9.75-1.672.006-3.038,9.73-1.637-5.715-8.081,2.152-2.139,8.04,5.723,1.672-9.75Z" transform="translate(-25.615 -22.822)" fill="currentColor" fill-rule="evenodd"></path>
								</svg>
							</div>

							<div>

								<h2>
									Nuestra misión
								</h2>

								Impulsar la eficiencia y la confiabilidad en la industria eléctrica nacional, estableciendo estándares de calidad y servicios que inspiren confianza, generando lealtad en nuestros clientes en todo el país y proporcionandoles en cada interacción una experiencia amena. Deseamos ser reconocidos como tu socio confiable en el sector, contribuyendo así al éxito y la eficiencia de nuestros clientes.

							</div>

						</div>

						<div className="content col-lg-6 col-md-12">

							<div>
								<svg xmlns="http://www.w3.org/2000/svg" width="34.805" height="34.805" viewBox="0 0 34.805 34.805">
									<path id="Trazado_27" data-name="Trazado 27" d="M44.537,22.828l1.637,9.729,8.081-5.714,2.139,2.152-5.723,8.04,9.75,1.672-.006,3.038L50.685,43.38l5.714,8.08L54.248,53.6l-8.04-5.723-1.672,9.75L41.5,57.621l-1.637-9.73-8.08,5.715-2.14-2.152,5.723-8.04-9.75-1.672.006-3.038,9.73-1.637-5.715-8.081,2.152-2.139,8.04,5.723,1.672-9.75Z" transform="translate(-25.615 -22.822)" fill="currentColor" fill-rule="evenodd"></path>
								</svg>
							</div>

							<div>

								<h2>
									Nuestra visión
								</h2>

								Nos proyectamos como referentes en el sector nacional de motores eléctricos, siendo reconocidos por nuestra capacidad técnica, innovación en tecnologías digitales y compromiso con la experiencia del cliente. Nos esforzamos por ser la primera opción para empresas y profesionales que buscan soluciones integrales para sus necesidades eléctricas, ofreciendo no solo productos y servicios de alta calidad, sino también una experiencia en todas las etapas de interacción.

							</div>

						</div>

					</div>

				</div>

			</section>
		)
	}

	return (
		<>
			{renderBanner()}
			{renderCards()}
			{renderDescription()}
			{renderMision()}
		</>
	)
}

export default Nosotros;