import React, { useState, useEffect } from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table as BsTable } from 'react-bootstrap'
import { Button } from 'components'

const Table = (props) => {
    const [value, setValue] = useState([]);

    useEffect(() => {
        setValue(props.value || []);
    }, [props.value]);

    const handleRemove = (rowIndex) => {
        let newValue = [...value];

        newValue.splice(rowIndex, 1);

        update(newValue);
    }

    const handleAddItem = () => {
        let newRow;

        props.columns.forEach(x => {
            newRow = {
                ...newRow,
                [x.key]: null
            };
        });

        if (props.newRowGetter)
            newRow = { ...newRow, ...props.newRowGetter() };

        update([...value, newRow]);
    }

    const handleChange = (v, rowIndex, colKey) => {
        let compValue;

        if (v === null || v === undefined || v === "")
            compValue = null;
        else if (Array.isArray(v))
            compValue = v.map(x => x.value);
        else if (typeof v === "object" && v.value)
            compValue = v.value;
        else
            compValue = v;

        let newValue = [...value];

        newValue[rowIndex][colKey] = compValue;

        update(newValue);
    }

    const update = (newValue) => {
        setValue(newValue);

        if (props.formControl && props.formManaged)
            props.onFieldChange && props.onFieldChange(newValue, props.name);
        else
            props.onChange && props.onChange(newValue);
    }

    const renderAddButton = () => {
        if (!props.enableAdd)
            return;

        return (
            <div style={{ marginTop: "10px" }}>
                <Button
                    variant="success"
                    size="sm"
                    style={{ height: "22px", lineHeight: "10px", fontSize: "13px" }}
                    onClick={handleAddItem}>
                    {props.addItemLegend}
                </Button>
            </div>
        )
    }

    if (!props.displayEmptyHeaders && (!value || value.length === 0)) {
        return (
            <React.Fragment>
                <div style={{ fontSize: "14px" }}>
                    {props.noItemsLegend}
                </div>
                {renderAddButton()}
            </React.Fragment>
        )
    }

    const table = <BsTable>
        <thead>
            <tr>
                {props.columns.map((col, i) => {
                    return (
                        <th
                            key={i}
                            width={col.width}>
                            {col.name}
                            {col.required && <span style={{ color: "red", fontWeight: "bold", marginLeft: "5px" }}>*</span>}
                        </th>
                    )
                })}

                {props.enableRemove &&
                    <th style={{ width: "50px" }}></th>}
            </tr>
        </thead>
        <tbody>
            {value && value.map((v, i) => {
                return (
                    <tr key={i}>
                        {props.columns.map((col, j) => {
                            const Comp = col.comp;
                            const value = v[col.key];

                            if (col.render && typeof col.render === "function") {
                                return (
                                    <td key={j}>
                                        {col.render(v)}
                                    </td>
                                )
                            }

                            return (
                                <td key={j}>
                                    {Comp ?
                                        <Comp
                                            {...col.props}
                                            value={value}
                                            onChange={(value) => handleChange(value, i, col.key)} /> :
                                        value}
                                </td>
                            )
                        })}

                        {props.enableRemove &&
                            <td>
                                <FontAwesomeIcon
                                    style={{ fontSize: "16px", color: "#eb2b2b", cursor: "pointer", position: "relative", top: "3px" }}
                                    onClick={() => handleRemove(i)}
                                    icon={faTimes} />
                            </td>}
                    </tr>
                )
            })}
        </tbody>
    </BsTable>;

    return (
        <React.Fragment>
            {props.scrollable ?
                <div style={{ overflowX: "auto", height: props.scrollHeight }}>
                    {table}
                </div> :
                table}
            {renderAddButton()}
        </React.Fragment>
    )
}

export default Table;

Table.defaultProps = {
    formControl: true,
    formManaged: false,
    enableRemove: false,
    enableAdd: false,
    noItemsLegend: "No items...",
    addItemLegend: "Agregar Item",
    displayEmptyHeaders: false,
    scrollable: false,
    scrollHeight: "300px"
};