import React from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUserContext } from 'modules/contexts/UserContext'
import { Link, useNavigate } from 'react-router-dom'
import * as Urls from 'modules/Urls'
import * as BrowserStorage from 'modules/BrowserStorage'
import { faUser } from '@fortawesome/free-solid-svg-icons'

const Header = (props) => {
	const { setUser, user } = useUserContext();
	const navigate = useNavigate();

	const handleLogout = () => {
		// Remove user from context
		setUser(null);

		// Remove user from local storage
		BrowserStorage.remove("user");

		navigate(Urls.Home);
	}

	return (
		<Navbar collapseOnSelect style={{ height: "62px", fontSize: "15px", backgroundColor: "white" }}>
			<Navbar.Brand as={Link} to={Urls.CRM}>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="mr-auto">
					{/* <Nav.Link as={Link} to={Urls.Info}>Info</Nav.Link>
                    <Nav.Link as={Link} to={Urls.UsuarioSetup}>Usuarios</Nav.Link> */}

					<NavDropdown title="Admin" id="basic-nav-dropdown">
						<NavDropdown.Item as={Link} to={Urls.ProductoSetup}>Productos</NavDropdown.Item>
						<NavDropdown.Item as={Link} to={Urls.TipoProductoSetup}>Tipos de Productos</NavDropdown.Item>
						<NavDropdown.Divider />
						<NavDropdown.Item as={Link} to={Urls.PotenciaSetup}>Potencias</NavDropdown.Item>
						<NavDropdown.Item as={Link} to={Urls.CarcasaSetup}>Carcasas</NavDropdown.Item>
						<NavDropdown.Item as={Link} to={Urls.ProteccionSetup}>Protecciones</NavDropdown.Item>
						<NavDropdown.Item as={Link} to={Urls.VoltajeSetup}>Voltajes</NavDropdown.Item>
						<NavDropdown.Item as={Link} to={Urls.RotacionSincronicaSetup}>Rotaciones sincrónicas</NavDropdown.Item>
						<NavDropdown.Item as={Link} to={Urls.EmbalajeSetup}>Embalajes</NavDropdown.Item>
					</NavDropdown>

					<NavDropdown title="Pedidos" id="basic-nav-dropdown">
						<NavDropdown.Item as={Link} to={Urls.PresupuestoSetup}>Presupuestos</NavDropdown.Item>
						<NavDropdown.Item as={Link} to={Urls.SolicitudDePedidoSetup}>Solicitudes de pedido</NavDropdown.Item>
						<NavDropdown.Item as={Link} to={Urls.PedidoWebSetup}>Pedidos Web</NavDropdown.Item>
					</NavDropdown>

					<NavDropdown title="Trabajos" id="basic-nav-dropdown">
						<NavDropdown.Item as={Link} to={Urls.TrabajoSetup}>Trabajos</NavDropdown.Item>
					</NavDropdown>

					<Nav.Link as={Link} to={Urls.ClienteSetup}>Clientes</Nav.Link>
					<Nav.Link as={Link} to={Urls.ContactoSetup}>Contactos</Nav.Link>

				</Nav>
			</Navbar.Collapse>
			<Navbar.Collapse className="justify-content-end" style={{ marginRight: "20px" }}>
				<Navbar.Text style={{ marginRight: "20px" }}>
					<div style={{ color: "#1291cd" }}>
						<FontAwesomeIcon
							icon={faUser}
							style={{ marginRight: "5px" }} />
						{user.nombreUsuario}
					</div>
				</Navbar.Text>
				<Navbar.Text
					style={{ cursor: "pointer" }}
					onClick={handleLogout} >
					Cerrar sesion
				</Navbar.Text>
			</Navbar.Collapse>
		</Navbar>
	)
}

export default Header;