import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { fetchUrl } from 'modules/util/functions'

const PrintPresupuesto = () => {
	const { id } = useParams();
	const [presupuesto, setPresupuesto] = useState({});
	const logoUrl = `/images/logo.balsolutions.svg`;
	const logoWeg = "/images/weg.svg";
	const footerImg = "/images/PresupuestoFooter.png";

	useEffect(() => {
		document.body.classList.add("public");
		document.body.style.backgroundColor = "white";

		fetchUrl("/presupuestoSetup/getPresupuestoInfo", "get", { idPresupuesto: id }, {
			success: (r) => {
				setPresupuesto(r.data);
			}
		}, { block: false });
	}, [id]);

	const renderMonto = (monto) => {
		if (presupuesto.codigoMoneda === "DOLAR")
			return "U$S " + (monto || "");

		return "$ " + (monto || "");
	}

	const renderObservaciones = () => {
		if (presupuesto.codigoMoneda === "PESO")
			return "Los precios cotizados son netos, unitarios y no incluyen IVA. Están expresados en pesos argentinos.";

		if (presupuesto.codigoDolar === "BILLETE")
			return "Los precios cotizados son netos, unitarios y no incluyen IVA. Están expresados en dólares estadounidenses, pagadero en dólares o en su defecto en pesos según cotización del dólar billete tipo vendedor fuente BNA del día anterior al efectivo pago.";

		// Dolar divisa
		return "Los precios cotizados son netos, unitarios y no incluyen IVA. Están expresados en dólares estadounidenses, pagadero en dólares o en su defecto en pesos según cotización del dólar divisa tipo vendedor fuente BNA del día anterior al efectivo pago.";
	}

	return (
		<>
			<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
				<img src={logoUrl} height={"60px"} alt="BALSOLUTIONS" />
				<div style={{ display: "flex" }}>
					<div style={{ color: "black", fontSize: "14px", marginTop: "10px", fontFamily: "auto", paddingLeft: "75px" }}>ASISTENTE TÉCNICO AUTORIZADO</div>
					<img src={logoWeg} height={"70px"} alt="weg" />
				</div>
			</div>
			<div style={{ marginTop: "5px", fontSize: "13px" }}>
				<div>Provincia de Buenos Aires 450 - Parque Industrial COMIRSA II - Ramallo – Buenos Aires</div>
				<div>Contactos: +54 9 336 463 0781 / 2 - 7179 – email: administracion@balsolutions.com.ar - www.balsolutions.com.ar</div>
			</div>
			<div style={{ marginTop: "15px" }}>
				<table style={{ width: '99%' }}>
					<tbody>
						<tr>
							<td style={{ fontWeight: 'bold', padding: '8px', border: '1px solid #000', width: '30%' }}>PRESUPUESTO: {presupuesto.numero}</td>
							<td style={{ fontWeight: 'bold', padding: '8px', border: '1px solid #000', width: '30%' }}>FECHA: {moment(presupuesto.fecha).format("DD/MM/YYYY")}</td>
						</tr>
						<tr>
							<td style={{ fontWeight: 'bold', padding: '8px', border: '1px solid #000', width: '30%' }}>EMPRESA: {presupuesto.nombreCliente}</td>
							<td style={{ fontWeight: 'bold', padding: '8px', border: '1px solid #000', width: '30%' }}>DIRECCION: {presupuesto.direccion}</td>
						</tr>
						<tr>
							<td style={{ fontWeight: 'bold', padding: '8px', border: '1px solid #000', width: '30%' }}>SOLICITO: {presupuesto.solicito}</td>
							<td style={{ fontWeight: 'bold', padding: '8px', border: '1px solid #000', width: '30%' }}>PEDIDO: {presupuesto.numeroSolicitudDePedido}</td>
						</tr>
					</tbody>
				</table>
			</div>

			{presupuesto.items &&
				<div style={{ marginTop: "15px" }}>
					<table style={{ width: '99%', tableLayout: "fixed" }}>
						<thead>
							<tr>
								<th style={{ fontWeight: 'bold', padding: '3px', border: '1px solid #000', textAlign: "center", width: "50px" }}>Item</th>
								<th style={{ fontWeight: 'bold', padding: '3px', border: '1px solid #000', textAlign: "center", width: "50px" }}>Cant</th>
								<th style={{ fontWeight: 'bold', padding: '3px', border: '1px solid #000', textAlign: "center" }}>Descripción</th>
								<th style={{ fontWeight: 'bold', padding: '3px', border: '1px solid #000', textAlign: "center", width: "100px" }}>Precio Unitario</th>
							</tr>
						</thead>
						<tbody>
							{presupuesto.items.map((item, index) => (
								<tr key={index}>
									<td style={{ padding: '3px', border: '1px solid #000', textAlign: "center", fontWeight: "bold" }}>{index + 1}</td>
									<td style={{ padding: '3px', border: '1px solid #000', textAlign: "center", fontWeight: "bold" }}>{item.cantidad}</td>
									<td style={{ padding: '3px', border: '1px solid #000', lineHeight: "20px" }}>{item.descripcion}</td>
									<td style={{ padding: '3px', border: '1px solid #000', textAlign: "center", fontWeight: "bold" }}>{renderMonto(item.monto)}</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="4" style={{ border: '1px solid #000', padding: '8px', textAlign: 'right', fontWeight: 'bold' }}>
									Precio Neto Total: {renderMonto(presupuesto.montoTotal)}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>}

			<div style={{ marginTop: "15px" }}>
				<div style={{ border: '1px solid #000', padding: '5px', width: "99%" }}>
					<strong>OBSERVACIONES: {renderObservaciones()}</strong>
				</div>
			</div>

			<div style={{ marginTop: "15px" }}>
				<table style={{ width: '99%' }}>
					<tbody>
						<tr>
							<td style={{ fontWeight: 'bold', padding: '8px', border: '1px solid #000', width: '30%' }}>PLAZO DE ENTREGA: {presupuesto.plazoEntrega}</td>
							<td style={{ fontWeight: 'bold', padding: '8px', border: '1px solid #000', width: '30%' }}>GARANTIA: {presupuesto.garantia}</td>
						</tr>
						<tr>
							<td style={{ fontWeight: 'bold', padding: '8px', border: '1px solid #000', width: '30%' }}>FORMA DE PAGO: {presupuesto.formaPago}</td>
							<td style={{ fontWeight: 'bold', padding: '8px', border: '1px solid #000', width: '30%' }}>VALIDEZ DE LA OFERTA: {presupuesto.validezOferta}</td>
						</tr>
						<tr>
							<td colSpan={2} style={{ fontWeight: 'bold', padding: '8px', border: '1px solid #000', width: '30%' }}>LUGAR DE ENTREGA: {presupuesto.lugarEntrega}</td>
						</tr>
						<tr>
							<td colSpan={2} style={{ fontWeight: 'bold', padding: '8px', border: '1px solid #000', width: '30%' }}>OTROS: {presupuesto.otros}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div style={{ marginTop: "15px" }}>
				<img src={footerImg} alt="footer" style={{ width: "99%" }} />
			</div>
		</>
	)
}

export default PrintPresupuesto;