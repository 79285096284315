import { Button } from "components";
import { fetchUrl } from 'modules/util/functions'
import React, { useState } from 'react';

const errorCodes = {
	NOMBRE_REQUERIDO: "NOMBRE_REQUERIDO",
	TELEFONO_REQUERIDO: "TELEFONO_REQUERIDO",
	EMAIL_REQUERIDO: "EMAIL_REQUERIDO",
	CONSULTA_REQUERIDA: "CONSULTA_REQUERIDA"
};

const ContactForm = () => {
	const [contactForm, setContactForm] = useState({});
	const [errors, setErrors] = useState([]);
	const [success, setSuccess] = useState(false);

	const handleContactFormChange = (change) => {
		setContactForm({ ...contactForm, ...change });
	}

	const handleEnviarFormClick = () => {
		const errors = [];

		if (!contactForm.nombre) {
			errors.push({
				errorCode: errorCodes.NOMBRE_REQUERIDO,
				errorMessage: "El nombre es requerido"
			});
		}

		if (!contactForm.telefono) {
			errors.push({
				errorCode: errorCodes.TELEFONO_REQUERIDO,
				errorMessage: "El telefono es requerido"
			});
		}

		if (!contactForm.email) {
			errors.push({
				errorCode: errorCodes.EMAIL_REQUERIDO,
				errorMessage: "El email es requerido"
			});
		}

		if (!contactForm.consulta) {
			errors.push({
				errorCode: errorCodes.CONSULTA_REQUERIDA,
				errorMessage: "La consulta es requerida"
			});
		}

		if (!errors.length) {
			setContactForm({
				nombre: "",
				telefono: "",
				email: "",
				consulta: ""
			});
			setErrors([]);

			fetchUrl("/contactar/contactar", "post", contactForm, {
				success: (r) => {
					setSuccess(true);
				}
			});
		} else
			setErrors(errors);
	}

	return (
		<div style={{ marginTop: "50px" }}>
			{success &&
				<div style={{ marginLeft: "10px", marginBottom: "10px" }} className="text-success">Su cosulta ha sido enviada. En breve nos pondremos en contacto con usted.</div>}

			<input
				placeholder="Nombre y Apellido"
				type="text"
				className="form-control contact-form mb-3 text"
				id="basic-url2"
				aria-describedby="basic-addon3"
				onChange={(e) => handleContactFormChange({ nombre: e.target.value })}
				value={contactForm.nombre} />
			{errors.some(x => x.errorCode === errorCodes.NOMBRE_REQUERIDO) && (
				<div style={{ marginLeft: "10px", marginBottom: "10px" }} className="text-danger">{errors.find(x => x.errorCode === errorCodes.NOMBRE_REQUERIDO).errorMessage}</div>)}
			<input
				placeholder="Teléfono de Contacto"
				type="number"
				className="form-control contact-form mb-3 text"
				id="basic-url2"
				aria-describedby="basic-addon3"
				onChange={(e) => handleContactFormChange({ telefono: e.target.value })}
				value={contactForm.telefono} />
			{errors.some(x => x.errorCode === errorCodes.TELEFONO_REQUERIDO) && (
				<div style={{ marginLeft: "10px", marginBottom: "10px" }} className="text-danger">{errors.find(x => x.errorCode === errorCodes.TELEFONO_REQUERIDO).errorMessage}</div>
			)}
			<input
				placeholder="Correo Electrónico"
				type="email"
				className="form-control contact-form mb-3 text"
				id="basic-url2"
				aria-describedby="basic-addon3"
				onChange={(e) => handleContactFormChange({ email: e.target.value })}
				value={contactForm.email} />
			{errors.some(x => x.errorCode === errorCodes.EMAIL_REQUERIDO) && (
				<div style={{ marginLeft: "10px", marginBottom: "10px" }} className="text-danger">{errors.find(x => x.errorCode === errorCodes.EMAIL_REQUERIDO).errorMessage}</div>
			)}
			<input
				placeholder="Escribe tu Consulta"
				type="text"
				className="form-control contact-form mb-3 text"
				id="basic-url2"
				aria-describedby="basic-addon3"
				onChange={(e) => handleContactFormChange({ consulta: e.target.value })}
				value={contactForm.consulta} />
			{errors.some(x => x.errorCode === errorCodes.CONSULTA_REQUERIDA) && (
				<div style={{ marginLeft: "10px", marginBottom: "10px" }} className="text-danger">{errors.find(x => x.errorCode === errorCodes.CONSULTA_REQUERIDA).errorMessage}</div>
			)}
			<Button
				style={{ width: "100%", marginTop: "20px" }}
				onClick={handleEnviarFormClick}>
				Enviar
			</Button>
		</div>
	);
}

export default ContactForm;