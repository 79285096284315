import React from 'react'
import Search from './Search'
import SetupPage from 'modules/setup/SetupPage'
import PedidoWebInfoTab from './pedidoWebInfo/PedidoWebInfoTab'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

const PedidoWebSetup = () => {

	const setupProps = {
		title: "Pedidos Web",
		icon: faSquare,
		searchUrl: "/pedidoWebSetup/search",
		rowKey: "idPedidoWeb",
		columns: [
			{ key: "idPedidoWeb", name: "ID", sortable: true, width: 60 },
			{ key: "empresa", name: "Empresa", sortable: true },
			{ key: "nombre", name: "Nombre", sortable: true },
			{ key: "cuit", name: "Cuit", sortable: true }
		],
		tabs: [
			{ key: "info", title: "Info", comp: PedidoWebInfoTab }
		]
	};

	return (
		<SetupPage
			setupProps={setupProps}
			search={Search} />
	)
}

export default PedidoWebSetup;