import React, { useState, useEffect } from 'react'
import { Combo } from 'components'
import { fetchUrl } from 'modules/util/functions'

const ClienteCombo = (props) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetchUrl("/query/getClientes", "get", {}, {
            success: (r) => {
                setItems(r.data.items.map(x => ({
                    value: x.idCliente,
                    label: x.nombre
                })));
            }
        }, { block: false });
    }, []);

    return (
        <Combo
            {...props}
            items={items} />
    )
}

export default ClienteCombo;

ClienteCombo.defaultProps = {
    formControl: true,
    formManaged: false
};