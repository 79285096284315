import React from 'react'
import { Combo } from 'components'

const TipoDolarCombo = (props) => {

    const items = [
        { value: "BILLETE", label: "Billete" },
        { value: "DIVISA", label: "Divisa" }
    ];

    return (
        <Combo
            {...props}
            items={items} />
    )
}

export default TipoDolarCombo;

TipoDolarCombo.defaultProps = {
    formControl: true,
    formManaged: false
};