import * as BrowserStorage from 'modules/BrowserStorage'
import * as Block from 'modules/Block'

export const fetchUrl = (url, method, rq, callbacks, opts = { block: true }) => {
    const jwtToken = BrowserStorage.get("user")?.token;
    const endpoint = process.env.NODE_ENV === "development" ? "https://localhost:44308" : "https://agubbb1995-001-site1.ctempurl.com";

    let uri = endpoint + url;

    let headers = {
        "Content-Type": "application/json",
        "Authorization-Token": jwtToken
    };

    let body;
    method = method.toUpperCase();

    if (method === 'POST')
        body = JSON.stringify(rq);
    else if (method === 'GET') {
        const params = Object
            .keys(rq)
            .filter(key => rq[key] !== null)
            .map(key => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(rq[key])}`;
            })
            .join('&');

        uri += `?${params}`;
    }

    console.log(`RQ ${uri}`, rq);

    if (opts.block)
        Block.block();

    fetch(uri, {
        method,
        headers,
        body
    })
        .then(response => {
            if (opts.block)
                Block.unBlock();

            // Unauthorized?
            if (response.status === 401 && window.location.href !== "/") {
                BrowserStorage.remove("user");
                window.location.replace("/")

                return;
            }

            return response.json()
        })
        .then(json => {
            console.log("RES ", json);

            if (!json.status && callbacks && callbacks.error)
                return callbacks.error(json);

            if (json.status && callbacks && callbacks.success)
                return callbacks.success(json);
        });
}