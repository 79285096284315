import React, { useMemo, useRef } from 'react'
import SetupTab from 'modules/setup/setupTab/SetupTab'
import { Label, RemoveModal, Panel } from 'components'
import { Row, Col, FormGroup } from 'react-bootstrap'
import { fetchUrl } from 'modules/util/functions'
import { useSetupTabValueContext, useSetupTabActionsContext } from 'modules/setup/setupTab/SetupTabContext'
import { Link } from 'react-router-dom'
import * as Urls from 'modules/Urls'

const PedidoWebInfoTab = () => {
	const { selectedId, form } = useSetupTabValueContext();
	const { onDelete, onError, onSuccess } = useSetupTabActionsContext();
	const _removeModal = useRef();

	const configuration = useMemo(() => ({
		urls: {
			get: "/pedidoWebSetup/getPedidoWebInfo"
		},
		getRequest: () => {
			return {
				idPedidoWeb: selectedId
			}
		}
	}), [selectedId]);

	const actions = [{
		key: "delete",
		name: "Borrar",
		onClick: () => {
			handleDelete();
		}
	}];

	// Handlers
	const handleDelete = () => {
		const rq = {
			idPedidoWeb: selectedId
		};

		_removeModal.current.open({
			title: "Eliminando pedido web",
			name: form.nombre,
			onYes: () => {
				fetchUrl("/pedidoWebSetup/deletePedidoWeb", "post", rq, {
					success: (r) => {
						onSuccess(r.message);
						onDelete();
					},
					error: (r) => {
						onError(r.errorMessage);
					}
				}, { block: false });
			}
		});
	}

	return (
		<React.Fragment>
			<RemoveModal
				ref={_removeModal} />

			<SetupTab
				configuration={configuration}
				actions={actions}
				displaySave={false}>
				<Row>
					<Col md={4}>
						<FormGroup>
							<Label>Nombre</Label>
							<div>{form.nombre}</div>
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Apellido</Label>
							<div>{form.apellido}</div>
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Cuit</Label>
							<div>{form.cuit}</div>
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Direccion</Label>
							<div>{form.direccionCalle}</div>
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Codigo Postal</Label>
							<div>{form.codigoPostal}</div>
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Ciudad</Label>
							<div>{form.ciudad}</div>
						</FormGroup>
					</Col>
					<Col md={4}>
						<FormGroup>
							<Label>Provincia</Label>
							<div>{form.provincia}</div>
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Email</Label>
							<div>{form.email}</div>
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Telefono</Label>
							<div>{form.telefono}</div>
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Medio de Pago</Label>
							<div>{form.medioDePago}</div>
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Empresa</Label>
							<div>{form.empresa}</div>
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Fecha</Label>
							<div>{form.fecha}</div>
						</FormGroup>
					</Col>
					<Col md={4}>
						<Panel.Panel variant="primary">
							<Panel.Header>Productos</Panel.Header>
							<Panel.Body>
								{form.productos && form.productos.map(x => {
									return (
										<Row>
											<Col md={1}>
												<b>{`${x.cantidad}x`}</b>
											</Col>
											<Col md={11}>
												<Link target="_blank" to={`/${Urls.CRM}/${Urls.ProductoSetup}/${x.idProducto}`} style={{ color: "black" }}>
													{x.nombre}
												</Link>
											</Col>
										</Row>
									)
								})}
							</Panel.Body>
						</Panel.Panel>
					</Col>
				</Row>
			</SetupTab>
		</React.Fragment>
	)
}

export default PedidoWebInfoTab;