import moment from 'moment'
import * as Formatting from './Formatting'

export const CurrencyFormatter = ({ value, row }) => {
    if (value === undefined || value === null)
        return null;

    return Formatting.formatCurrency(value, row.codigoMoneda);
}

export const DateFormatter = ({ value }) => {
    if (!value)
        return null;

    return moment(value).format("DD MMM YYYY");
}