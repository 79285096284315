import React from 'react'
import Search from './Search'
import SetupPage from 'modules/setup/SetupPage'
import ClienteInfoTab from './clienteInfo/ClienteInfoTab'
import ContactosTab from './contactos/ContactosTab'
import { faUsers } from '@fortawesome/free-solid-svg-icons'

const ClienteSetup = () => {

    const setupProps = {
        title: "Clientes",
        icon: faUsers,
        addNewTitle: "Nuevo cliente",
        searchUrl: "/cliente/search",
        rowKey: "idCliente",
        columns: [
            { key: "idCliente", name: "ID", sortable: true, width: 60 },
            { key: "nombre", name: "Nombre", sortable: true },
            { key: "cuit", name: "Cuit", sortable: true },
            { key: "pagaEnDias", name: "Paga en dias", sortable: true },
            { key: "direccion", name: "Direccion", sortable: true }
        ],
        tabs: [
            { key: "clienteInfo", title: "Info de Cliente", comp: ClienteInfoTab },
            { key: "contactos", title: "Contactos", comp: ContactosTab }
        ]
    };

    return (
        <SetupPage
            setupProps={setupProps}
            search={Search} />
    )

}

export default ClienteSetup;