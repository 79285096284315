import React from 'react'
import Search from './Search'
import SetupPage from 'modules/setup/SetupPage'
import SolicitudDePedidoInfoTab from './solicitudDePedidoInfo/SolicitudDePedidoInfoTab'
import { faPager } from '@fortawesome/free-solid-svg-icons'
import * as Formatters from 'modules/Formatters'

const SolicitudDePedidoSetup = () => {

	const setupProps = {
		title: "Solicitudes de pedido",
		icon: faPager,
		addNewTitle: "Nueva solicitud",
		searchUrl: "/solicitudDePedidoSetup/search",
		rowKey: "idSolicitudDePedido",
		columns: [
			{ key: "idSolicitudDePedido", name: "ID", sortable: true, width: 60 },
			{ key: "numero", name: "Numero", sortable: true },
			{ key: "cliente", name: "Cliente", sortable: false },
			{ key: "persona", name: "Persona", sortable: true },
			{ key: "fecha", name: "Fecha", sortable: true, formatter: Formatters.DateFormatter },
			{ key: "solicitudDePedido", name: "Sol. de pedido", sortable: true }
		],
		tabs: [
			{ key: "solicitudDePedidoInfo", title: "Info de la solicitud", comp: SolicitudDePedidoInfoTab }
		]
	};

	return (
		<SetupPage
			setupProps={setupProps}
			search={Search} />
	)

}

export default SolicitudDePedidoSetup;