import React from 'react'
import Search from './Search'
import SetupPage from 'modules/setup/SetupPage'
import ContactoInfoTab from './contactoInfo/ContactoInfoTab'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

const ContactoSetup = () => {

    const setupProps = {
        title: "Contactos",
        icon: faPhone,
        addNewTitle: "Nuevo contacto",
        searchUrl: "/contacto/search",
        rowKey: "idContacto",
        columns: [
            { key: "idContacto", name: "ID", sortable: true, width: 60 },
            { key: "nombre", name: "Nombre", sortable: true },
            { key: "telefono", name: "Telefono", sortable: false },
            { key: "tipo", name: "Tipo", sortable: true }
        ],
        tabs: [
            { key: "contactoInfo", title: "Info de Contacto", comp: ContactoInfoTab }
        ]
    };

    return (
        <SetupPage
            setupProps={setupProps}
            search={Search} />
    )

}

export default ContactoSetup;