import React from 'react'
import DateTime from 'react-datetime'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import 'react-datetime/css/react-datetime.css'
import 'moment/locale/es'

const DatePicker = (props) => {

    // Handlers
    const handleChange = (value) => {
        if (value)
            value = value._d;

        if (props.formControl && props.formManaged)
            props.onFieldChange && props.onFieldChange(value, props.name);
        else
            props.onChange && props.onChange(value);
    }

    const opts = {
        value: props.value,
        timeFormat: props.timeFormat,
        closeOnSelect: true,
        dateFormat: "DD MMM YYYY",
        onChange: handleChange
    };

    const inputProps = {
        value: props.value ? moment(props.value).format("DD MMM YYYY") : "",
        disabled: props.disabled,
        readOnly: true,
        placeholder: props.placeholder
    };

    return (
        <div style={{ position: "relative" }}>
            <DateTime
                {...opts}
                inputProps={inputProps} />
            {props.value &&
                <div
                    style={{ position: "absolute", top: 8, right: 10, fontSize: "12px", color: "#cccccc", cursor: "pointer" }}
                    onClick={() => handleChange(null)}>
                    <FontAwesomeIcon
                        icon={faTimes} />
                </div>}
        </div>
    )
}

export default DatePicker;

DatePicker.defaultProps = {
    formControl: true,
    formManaged: false,
    timeFormat: false
}