import React from 'react'

const Body = (props) => {

    return (
        <div className="panel-body">
            {props.children}
        </div>
    )
}

export default Body;