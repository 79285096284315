import React from 'react'
import { Form } from 'react-bootstrap'

const CheckBox = (props) => {
    // Handlers
    const handleChange = (e) => {
        if (props.formControl && props.formManaged) {
            props.onFieldChange && props.onFieldChange(e.target.checked, props.name);
            props.onChange && props.onChange(e.target.checked);
        }
        else
            props.onChange && props.onChange(e.target.checked);
    }

    // Control props
    const opts = {
        disabled: props.disabled,
        name: props.name,
        label: props.label,
        onChange: handleChange,
        checked: props.value,
        id: props.id || "custom-CheckBox",
        type: props.type
    };

    return (
        <Form.Check
            {...opts} />
    )
}

export default CheckBox;

// Default props
CheckBox.defaultProps = {
    formControl: true,
    formManaged: false,
    type: "checkbox",
    value: false
};