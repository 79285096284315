import React, { useMemo, useRef } from 'react'
import SetupTab from 'modules/setup/setupTab/SetupTab'
import { TextBox, Label, RemoveModal, DatePicker, TextArea, ClienteCombo } from 'components'
import { Row, Col, FormGroup } from 'react-bootstrap'
import { fetchUrl } from 'modules/util/functions'
import { useSetupTabValueContext, useSetupTabActionsContext } from 'modules/setup/setupTab/SetupTabContext'

const SolicitudDePedidoInfoTab = () => {
	const { selectedId, form } = useSetupTabValueContext();
	const { onDelete, onError, onSuccess } = useSetupTabActionsContext();
	const _removeModal = useRef();

	const configuration = useMemo(() => ({
		urls: {
			get: "/solicitudDePedidoSetup/getSolicitudDePedidoInfo",
			save: "/solicitudDePedidoSetup/saveSolicitudDePedidoInfo",
			create: "/solicitudDePedidoSetup/createSolicitudDePedido"
		},
		getRequest: () => {
			return {
				idSolicitudDePedido: selectedId
			}
		},
		saveRequest: (form) => {
			return {
				idSolicitudDePedido: selectedId,
				fecha: form.fecha,
				idCliente: form.idCliente,
				persona: form.persona,
				solicitudDePedido: form.solicitudDePedido,
				numero: form.numero,
				descripcionOriginal: form.descripcionOriginal,
				observaciones: form.observaciones
			}
		}
	}), [selectedId]);

	const actions = [
		{
			key: "delete",
			name: "Borrar",
			onClick: () => {
				handleDelete();
			}
		}
	];

	// Handlers
	const handleDelete = () => {
		const rq = {
			idSolicitudDePedido: selectedId
		};

		_removeModal.current.open({
			title: "Eliminando solicitud de pedido",
			name: `la solicitud de pedido N°${form.numero}`,
			onYes: () => {
				fetchUrl("/solicitudDePedidoSetup/deleteSolicitudDePedido", "post", rq, {
					success: (r) => {
						onSuccess(r.message);
						onDelete();
					},
					error: (r) => {
						onError(r.errorMessage);
					}
				}, { block: false });
			}
		});
	}

	return (
		<React.Fragment>
			<RemoveModal
				ref={_removeModal} />

			<SetupTab
				configuration={configuration}
				actions={actions}>
				<Row>
					<Col md={4}>
						<FormGroup>
							<Label required>Numero</Label>
							<TextBox
								name="numero"
								placeholder="Numero" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label required>Cliente</Label>
							<ClienteCombo
								name="idCliente" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Descripcion original</Label>
							<TextArea
								name="descripcionOriginal"
								placeholder="Descripcion original" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Observaciones</Label>
							<TextArea
								name="observaciones"
								placeholder="Observaciones" />
						</FormGroup>
					</Col>
					<Col md={4}>
						<FormGroup>
							<Label required>Fecha</Label>
							<DatePicker
								name="fecha"
								placeholder="Fecha" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Persona</Label>
							<TextBox
								name="persona"
								placeholder="Persona" />
						</FormGroup>
					</Col>
				</Row>
			</SetupTab>
		</React.Fragment>
	)
}

export default SolicitudDePedidoInfoTab;