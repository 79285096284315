import React from 'react'
import { Combo } from 'components'

const MonedaCombo = (props) => {

    const items = [
        { value: "PESO", label: "Peso" },
        { value: "DOLAR", label: "Dolar" }
    ];

    return (
        <Combo
            {...props}
            items={items} />
    )
}

export default MonedaCombo;

MonedaCombo.defaultProps = {
    formControl: true,
    formManaged: false
};