import React from 'react'

import './Panel.css'

const Panel = (props) => {

    return (
        <div className={`panel panel-${props.variant}`}>
            {props.children}
        </div>
    )
}

export default Panel;

Panel.defaultProps = {
    variant: "default"
};