import React from 'react'
import { Combo } from 'components'

const TipoPresupuestoCombo = (props) => {

    const items = [
        { value: "VENTA", label: "Venta" },
        { value: "REPARACION", label: "Reparacion" }
    ];

    return (
        <Combo
            {...props}
            items={items} />
    )
}

export default TipoPresupuestoCombo;

TipoPresupuestoCombo.defaultProps = {
    formControl: true,
    formManaged: false
};