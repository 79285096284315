import Routes from './Routes'
import * as Block from 'modules/Block'
import * as Notifications from 'modules/Notifications'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import "../index.css";

function App() {
  return (
    <>
      <Notifications.Toastr
        ref={Notifications.refHook} />
      <Block.Block
        ref={Block.refHook}>
        <Routes />
      </Block.Block>
    </>
  );
}

export default App;
