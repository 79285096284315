import React, { useMemo, useRef } from 'react'
import SetupTab from 'modules/setup/setupTab/SetupTab'
import { TextBox, TextArea, Label, RemoveModal, SolicitudDePedidoCombo, PedidoWebCombo, DatePicker, ClienteCombo, Panel, Table, DecimalBox, MonedaCombo, TipoPresupuestoCombo, TrabajoCombo, TipoDolarCombo } from 'components'
import { Row, Col, FormGroup } from 'react-bootstrap'
import { fetchUrl } from 'modules/util/functions'
import { useSetupTabValueContext, useSetupTabActionsContext } from 'modules/setup/setupTab/SetupTabContext'
import * as Urls from 'modules/Urls'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

const PresupuestoInfoTab = () => {
    const { selectedId, form, adding } = useSetupTabValueContext();
    const { onDelete, onError, onSuccess } = useSetupTabActionsContext();
    const _removeModal = useRef();

    const configuration = useMemo(() => ({
        urls: {
            get: "/presupuestoSetup/getPresupuestoInfo",
            save: "/presupuestoSetup/savePresupuestoInfo",
            create: "/presupuestoSetup/createPresupuesto",
            new: "/presupuestoSetup/newPresupuesto"
        },
        getRequest: () => {
            return {
                idPresupuesto: selectedId
            }
        },
        saveRequest: (form) => {
            return {
                idPresupuesto: selectedId,
                codigoTipoPresupuesto: form.codigoTipoPresupuesto,
                numero: form.numero,
                idCliente: form.idCliente,
                idSolicitudDePedido: form.idSolicitudDePedido,
                idPedidoWeb: form.idPedidoWeb,
                solicito: form.solicito,
                fecha: form.fecha,
                direccion: form.direccion,
                codigoMoneda: form.codigoMoneda,
                codigoDolar: form.codigoDolar,
                montoTotal: form.montoTotal,
                observaciones: form.observaciones,
                plazoEntrega: form.plazoEntrega,
                formaPago: form.formaPago,
                garantia: form.garantia,
                lugarEntrega: form.lugarEntrega,
                validezOferta: form.validezOferta,
                otros: form.otros,
                items: form.items
            }
        }
    }), [selectedId]);

    const actions = [
        {
            key: "delete",
            name: "Borrar",
            onClick: () => {
                handleDelete();
            }
        },
        {
            key: "print",
            name: "Imprimir",
            onClick: () => {
                handlePrint();
            },
            icon: faPrint
        }
    ];

    // Handlers
    const handlePrint = () => {
        window.open(`${Urls.PrintPresupuesto}/${selectedId}`, "_blank");
    }

    const handleDelete = () => {
        const rq = {
            idPresupuesto: selectedId
        };

        _removeModal.current.open({
            title: "Eliminando presupuesto",
            name: form.nombre,
            onYes: () => {
                fetchUrl("/presupuestoSetup/deletePresupuesto", "post", rq, {
                    success: (r) => {
                        onSuccess(r.message);
                        onDelete();
                    },
                    error: (r) => {
                        onError(r.errorMessage);
                    }
                }, { block: false });
            }
        });
    }

    const getColumns = () => {
        const columns = [
            { key: "cantidad", name: "Cantidad", comp: DecimalBox, width: 100, required: true },
            { key: "descripcion", name: "Descripcion", comp: TextBox, required: true },
            { key: "monto", name: "Monto", comp: DecimalBox, width: 100, required: true },
        ];

        if (form.codigoTipoPresupuesto === "REPARACION")
            columns.push({ key: "idTrabajo", name: "Trabajo", comp: TrabajoCombo });

        return columns;
    }

    return (
        <React.Fragment>
            <RemoveModal
                ref={_removeModal} />

            <SetupTab
                configuration={configuration}
                actions={actions}>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <Label required help={adding ? `El proximo numero de presupuesto es: ${form.proximoPresupuesto}` : null} style={{ marginBottom: "5px" }}>Numero</Label>
                            <TextBox
                                name="numero"
                                placeholder="Numero" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label required>Tipo</Label>
                            <TipoPresupuestoCombo
                                name="codigoTipoPresupuesto" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label required>Cliente</Label>
                            <ClienteCombo
                                name="idCliente" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Solicitud de pedido</Label>
                            <SolicitudDePedidoCombo
                                name="idSolicitudDePedido" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Pedido Web</Label>
                            <PedidoWebCombo
                                name="idPedidoWeb" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label required>Fecha</Label>
                            <DatePicker
                                name="fecha" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Solicito</Label>
                            <TextBox
                                name="solicito" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Direccion</Label>
                            <TextBox
                                name="direccion" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Plazo de entrega</Label>
                            <TextBox
                                name="plazoEntrega" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Forma de pago</Label>
                            <TextBox
                                name="formaPago" />
                        </FormGroup>
                    </Col>
                    <Col md={3}>

                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label required>Moneda</Label>
                            <MonedaCombo
                                name="codigoMoneda" />
                        </FormGroup>
                        {form.codigoMoneda === "DOLAR" &&
                            <FormGroup style={{ marginTop: "20px" }}>
                                <Label required>Tipo de dolar</Label>
                                <TipoDolarCombo
                                    name="codigoDolar" />
                            </FormGroup>}
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Monto total</Label>
                            <DecimalBox
                                name="montoTotal" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Observaciones</Label>
                            <TextArea
                                name="observaciones"
                                style={{ height: "117px" }} />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Garantia</Label>
                            <TextBox
                                name="garantia" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Lugar de entrega</Label>
                            <TextBox
                                name="lugarEntrega" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Validez de la oferta</Label>
                            <TextBox
                                name="validezOferta" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Otros</Label>
                            <TextArea
                                name="otros"
                                style={{ height: "117px" }} />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <Panel.Panel variant="info">
                            <Panel.Header>
                                Items
                            </Panel.Header>
                            <Panel.Body>
                                <Table
                                    name="items"
                                    columns={getColumns()}
                                    enableRemove
                                    enableAdd
                                    noItemsLegend="No hay items." />
                            </Panel.Body>
                        </Panel.Panel>
                    </Col>
                </Row>
            </SetupTab>
        </React.Fragment>
    )
}

export default PresupuestoInfoTab;