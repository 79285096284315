import React, { useState, useEffect } from 'react'
import Banner from './Banner';
import { fetchUrl } from 'modules/util/functions'
import ProductosHeader from './ProductosHeader';
import ProductCard from './ProductCard';

const itemsCount = 9;

const Productos = () => {
	const [productos, setProductos] = useState([]);
	const [tipoProductos, setTipoProductos] = useState([]);
	const [tipoProducto, setTipoProducto] = useState();
	const [tipoProductoRaiz, setTipoProductoRaiz] = useState();
	const [totalCount, setTotalCount] = useState();
	const [filtros, setFiltros] = useState({});
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const extendedPagination = totalPages > 5;
	const showPagination = totalPages > 1;

	document.title = "Nuestros productos - Bal Solutions";

	useEffect(() => {
		fetchUrl("/query/getTipoProductos", "get", {}, {
			success: (r) => {
				setTipoProductos(r.data.tipoProductos);
			}
		});
	}, []);

	useEffect(() => {
		const rq = {
			page: page,
			itemsCount: itemsCount,
			...(filtros || {})
		};

		fetchUrl("/query/getProductos", "post", rq, {
			success: (r) => {
				setProductos(r.data.productos);
				setTotalCount(r.data.totalCount);
				setTotalPages(Math.ceil(r.data.totalCount / itemsCount));
			}
		});
	}, [filtros, page]);

	const getPadre = (tipoProducto) => tipoProductos.find(tp => tp.idTipoProducto === tipoProducto.idTipoProductoPadre);

	// Handlers
	const handleSetTipoProducto = (tipoProducto) => {
		setTipoProducto(tipoProducto);
		setFiltros({
			...filtros,
			idTipoProducto: tipoProducto.idTipoProducto
		});

		if (!tipoProducto.idTipoProductoPadre)
			setTipoProductoRaiz(tipoProducto);
	}

	const handleRemoveTipoProducto = () => {
		if (tipoProducto.idTipoProductoPadre) {
			const padre = getPadre(tipoProducto);

			setTipoProducto(padre);
			setFiltros({
				...filtros,
				idTipoProducto: padre.idTipoProducto
			});
		}
		else {
			setTipoProducto(null);
			setTipoProductoRaiz(null);
			setFiltros({});
		}
	}

	const handlePageChange = (e, page) => {
		e.preventDefault();
		setPage(page);
	}

	const renderProductos = () => {
		const hijos = tipoProductos.filter(tp => tp.idTipoProductoPadre === tipoProducto?.idTipoProducto);

		return (
			<section className="products product-grid">

				<div className="container">

					<div className="row">

						<div className="filter col-lg-3 col-md-12 mb-4">

							{tipoProducto &&

								<div className="filter-apply">

									<div className="card mb-4 br-12">

										<div className="card-header" style={{ backgroundColor: "#cfe2ff" }}>

											<p className="mb-0 text-dark">
												<b>Filtros aplicados</b>
											</p>

										</div>

										<div className="card-body pb-0">

											<div className="col-12 mb-3">

												{!tipoProducto.idTipoProductoPadre ? "Tipo: " : getPadre(tipoProducto).nombre + ": "}
												<div className="badge bg-success text-success text-decoration-none p-2 me-3 text-normal"
													onClick={handleRemoveTipoProducto}>
													<div className="d-flex align-items-center mb-0">
														<div className="mb-0 me-1">{tipoProducto.nombre}</div>
														<div className="text-normal mb-0">x</div>
													</div>
												</div>

											</div>




											<p style={{ color: "var(--filter-link-color)" }}>
												<i className="bi bi-check-circle"></i> <b>{totalCount}</b> productos encontrados.
											</p>

										</div>

									</div>

								</div>}


							<div className="accordion" id="accordionpanelsStayOpenExample">

								{!tipoProducto &&
									<div className="accordion-item mb-0">

										<h5 className="accordion-header" id="panelsStayOpen-headingTipo">

											<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTipo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTipo">
												<b>
													Tipo
												</b>
											</button>

										</h5>

										<div id="panelsStayOpen-collapseTipo" className="accordion-collapse collapse show mb-0" aria-labelledby="panelsStayOpen-headingTipo">

											<div className="accordion-body mb-0">

												<ul id="cat-list" className="list-terms ps-0">

													{tipoProductos.filter(tp => !tp.idTipoProductoPadre).sort((a, b) => a.posicion - b.posicion).map((tp) => (
														<li>
															<div onClick={() => handleSetTipoProducto(tp)} style={{ cursor: "pointer" }}>
																<input className="form-check-input filter" type="radio" name="tipo" checked={tp.idTipoProducto === tipoProducto?.idTipoProducto} />&nbsp;{tp.nombre}
															</div>
														</li>
													))}

												</ul>

											</div>

										</div>

									</div>}

								{hijos && hijos.length > 0 &&

									<div className="accordion-item mb-0">

										<h5 className="accordion-header" id="panelsStayOpen-heading">
											<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse" aria-expanded="true" aria-controls="panelsStayOpen-collapse">
												<b>{tipoProducto.nombre}</b>
											</button>
										</h5>

										<div id="panelsStayOpen-collapse" className="accordion-collapse collapse show mb-0" aria-labelledby="panelsStayOpen-heading">

											<div className="accordion-body mb-0">

												<ul id="cat-list" className="list-terms ps-0">

													{hijos.sort((a, b) => a.posicion - b.posicion).map((tp) => (
														<li>
															<div onClick={() => handleSetTipoProducto(tp)} style={{ cursor: "pointer" }}>
																<input className="form-check-input filter" type="radio" name="tipo" checked={tp.idTipoProducto === tipoProducto?.idTipoProducto} />&nbsp;{tp.nombre}
															</div>
														</li>
													))}

												</ul>

											</div>

										</div>

									</div>}

							</div>

						</div>

						<div className="col-lg-9 col-md-12">

							<div className="row">

								{productos.map(p => {
									return (
										<div className="col-12 col-md-4 pb-4 d-none d-md-block">
											<ProductCard
												producto={p}
												tipoProductos={tipoProductos} />
										</div>
									)
								})}

							</div>

							<div className="row">

								{productos.map(p => {
									return (
										<div className="col-12 mb-3 d-block d-md-none">
											<ProductCard
												producto={p}
												tipoProductos={tipoProductos}
												mobile />
										</div>
									)
								})}

							</div>

							{showPagination &&
								<ul class="pagination">
									{!extendedPagination ? (
										<>
											{page > 1 && (
												<li className="border" onClick={(e) => handlePageChange(e, page - 1)}>
													<div className="page-numbers arrow">
														<i className="bi bi-chevron-left"></i>
													</div>
												</li>
											)}
											{[...Array(totalPages)].map((_, i) => {
												const style = {};

												if (i + 1 === page)
													style.color = "white";

												return (
													<li key={i} className={`border ${i + 1 === page ? "active" : ""}`} onClick={(e) => handlePageChange(e, i + 1)}>
														<div className="page-numbers"
															style={style}
														>
															{i + 1}
														</div>
													</li>
												)
											})}
											{page < totalPages && (
												<li className="border" onClick={(e) => handlePageChange(e, page + 1)}>
													<div className="page-numbers arrow"
													>
														<i className="bi bi-chevron-right"></i>
													</div>
												</li>
											)}
										</>
									) : (
										<>
											{page > 1 && (
												<li className="border" onClick={(e) => handlePageChange(e, page - 1)}>
													<div className="page-numbers arrow"
													>
														<i className="bi bi-chevron-left"></i>
													</div>
												</li>
											)}
											<li className={`border ${page === 1 ? "active" : ""}`} onClick={(e) => handlePageChange(e, 1)}>
												<div className="page-numbers"

													style={page === 1 ? { color: "white" } : {}}>
													1
												</div>
											</li>
											{page > 4 && <li className="border"><span className="page-numbers dots">...</span></li>}
											{[...Array(5)].map((_, i) => {
												const pageNumber = page - 2 + i;
												if (pageNumber > 1 && pageNumber < totalPages) {
													const style = {};
													if (pageNumber === page)
														style.color = "white";

													return (
														<li key={pageNumber} className={`border ${pageNumber === page ? "active" : ""}`} onClick={(e) => handlePageChange(e, pageNumber)}>
															<div className="page-numbers"
																style={style}
															>
																{pageNumber}
															</div>
														</li>
													)
												}
												return null;
											})}
											{page < totalPages - 3 && <li className="border"><span className="page-numbers dots">...</span></li>}
											<li className={`border ${page === totalPages ? "active" : ""}`} onClick={(e) => handlePageChange(e, totalPages)}>
												<div className="page-numbers"

													style={page === totalPages ? { color: "white" } : {}}>
													{totalPages}
												</div>
											</li>
											{page < totalPages && (
												<li className="border" onClick={(e) => handlePageChange(e, page + 1)}>
													<div className="page-numbers arrow"
													>
														<i className="bi bi-chevron-right"></i>
													</div>
												</li>
											)}
										</>
									)}
								</ul>}

						</div>

					</div>

				</div>

			</section>
		)
	}

	return (
		<>
			<Banner
				tipoProducto={tipoProducto} />
			<ProductosHeader
				tipoProductos={tipoProductos.filter(tp => !tp.idTipoProductoPadre).sort((a, b) => a.posicion - b.posicion)}
				tipoProducto={tipoProductoRaiz}
				onSetTipoProducto={handleSetTipoProducto} />
			{renderProductos()}
		</>
	)
}

export default Productos;