import React from 'react'
import SetupSearch from 'modules/setup/setupSearch/SetupSearch'
import { SearchBox, ClienteCombo } from 'components'
import { Row, Col } from 'react-bootstrap'

const Search = () => {

    return (
        <SetupSearch>
            <Row>
                <Col md={3}>
                    <SearchBox
                        name="searchText"
                        placeholder="Nombre, cuit..." />
                </Col>
                <Col md={2}>
                    <ClienteCombo
                        name="idCliente"
                        placeholder="Cliente" />
                </Col>
            </Row>
        </SetupSearch>
    )
}

export default Search;