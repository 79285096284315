import React from 'react'

const Header = (props) => {

    return (
        <div className="panel-header">
            {props.children}
        </div>
    )
}

export default Header;