import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchUrl } from 'modules/util/functions'

const InformacionPedido = () => {
	const [pedido, setPedido] = useState();
	const [loading, setLoading] = useState(true);
	const { id } = useParams();
	document.title = "Pedido - Bal Solutions";

	useEffect(() => {
		fetchUrl("/pedidoWeb/getpedidoWeb", "get", { idPedidoWeb: id }, {
			success: (r) => {
				setPedido(r.data);
				setLoading(false);
			}
		}, { block: true });
	}, [id]);

	if (loading)
		return <div></div>;

	if (!pedido)
		return <div>Pedido no encontrado.</div>;

	return (
		<section class="purchase-order">

			<div class="container">

				<div class="row">

					<div class="purchase-order-summary col-lg-8 col-md-12">

						<p class="shop-titles title">
							Seguimiento de pedido #{pedido.idPedidoWeb}
						</p>

						<div class="resume">

							<div>

								<p class="shop-titles">
									Productos
								</p>

								{pedido.productos.map(p => (
									<p>{p.nombre}</p>
								))}

							</div>

							<div>

								<p class="shop-titles">
									Cantidad
								</p>

								{pedido.productos.map(p => (
									<p style={{ textAlign: "right" }}>{p.cantidad} u.</p>
								))}

							</div>

						</div>

					</div>

					<div class="purchase-order-status col-lg-4 col-md-12">

						<div class="status">

							<div>
								<p class="shop-titles">Estado del pedido</p>
								<p>CONFIRMADO</p>
							</div>

							Hemos recibido tu solicitud de presupuesto. En breve nos pondremos en contacto.

						</div>

						<div class="payment">

							<p class="shop-titles">
								Medio de pago seleccionado
							</p>

							<p>
								{pedido.medioDePago}
							</p>

						</div>

						{/* <div class="shipping">

							<div>
								<p class="shop-titles">Seguimiento de env&iacute;o</p>
								<p>EN PROCESAMIENTO</p>
							</div>

							<p>
								<?php echo $descripcion_procesamiento; ?>
							</p>

						</div> */}
					</div>

				</div>

			</div>

		</section>
	)
};

export default InformacionPedido;