import React from 'react'
import Search from './Search'
import SetupPage from 'modules/setup/SetupPage'
import PresupuestoInfoTab from './presupuestoInfo/PresupuestoInfoTab'
import * as Formatters from 'modules/Formatters'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

const PresupuestoSetup = () => {

    const setupProps = {
        title: "Presupuestos",
        icon: faPaperPlane,
        addNewTitle: "Nuevo presupuesto",
        searchUrl: "/presupuestoSetup/search",
        rowKey: "idPresupuesto",
        columns: [
            { key: "idPresupuesto", name: "ID", sortable: true, width: 60 },
            { key: "numero", name: "Numero", sortable: true },
            { key: "codigoTipoPrespuesto", name: "Tipo", sortable: true },
            { key: "cliente", name: "Cliente", sortable: true },
            { key: "fecha", name: "Fecha", sortable: true, formatter: Formatters.DateFormatter },
        ],
        tabs: [
            { key: "presupuestoInfo", title: "Info de Presupuesto", comp: PresupuestoInfoTab }
        ]
    };

    return (
        <SetupPage
            setupProps={setupProps}
            search={Search} />
    )

}

export default PresupuestoSetup;