import React, { useState, useEffect } from 'react'
import { Combo } from 'components'
import { fetchUrl } from 'modules/util/functions'

const ContactoCombo = (props) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetchUrl("/query/getContactos", "get", {}, {
            success: (r) => {
                setItems(r.data.items.map(x => ({
                    value: x.idContacto,
                    label: x.nombre,
                    tipo: x.tipo,
                    telefono: x.telefono
                })));
            }
        }, { block: false });
    }, []);

    return (
        <Combo
            {...props}
            items={items} />
    )
}

export default ContactoCombo;

ContactoCombo.defaultProps = {
    formControl: true,
    formManaged: false
};