import React from 'react';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';
import { usePedidoContext } from 'modules/contexts/PedidoContext'
import * as BrowserStorage from 'modules/BrowserStorage'

const ProductCard = ({ producto, mobile }) => {
	const navigate = useNavigate();
	const { setPedido, pedido } = usePedidoContext();

	const handleProductoClick = () => {
		navigate(`./${producto.idProducto}`);
	}

	const handleAgregarProductoClick = (idProducto) => {
		setPedido((pedido) => {
			if (!pedido) {
				BrowserStorage.set("pedido", [{ idProducto, cantidad: 1 }]);
				return [{ idProducto, cantidad: 1 }];
			}

			BrowserStorage.set("pedido", [...pedido, { idProducto, cantidad: 1 }]);
			return [...pedido, { idProducto, cantidad: 1 }];
		});
	}

	const handleRmoverProductoClick = (idProducto) => {
		setPedido((pedido) => {
			BrowserStorage.set("pedido", pedido.filter(p => p.idProducto !== idProducto));
			return pedido.filter(p => p.idProducto !== idProducto);
		});
	}

	if (mobile) {
		return (
			<div className="product-card card border br-12">

				<div className="row">

					<div className="col-4 pe-0">

						<div className="overflow-hidden h-100">

							<div className="card-img-top position-relative bg-white h-100" style={{ cursor: "pointer" }} onClick={handleProductoClick}>
								<img className="img-responsive w-100" src={producto.urlImagen} alt={producto.nombre} />
							</div>

						</div>

					</div>

					<div className="col-8 ps-0">

						<div className="card-body">

							<h2 className="card-text fw-bold mb-3" style={{ cursor: "pointer" }} onClick={handleProductoClick}>
								{producto.nombre}
							</h2>


							<div className="taxonomies mb-2">
								{producto.nombreTipoProductoAbuelo &&
									<div className="badge bg-success text-success cat me-1 mb-2">{producto.nombreTipoProductoAbuelo}</div>}
								{producto.nombreTipoProductoPadre &&
									<div className="badge bg-success text-success cat me-1 mb-2">{producto.nombreTipoProductoPadre}</div>}
								<div className="badge bg-success text-success cat me-1 mb-2">{producto.nombreTipoProducto}</div>
								{producto.voltaje &&
									<div className="badge bg-warning text-warning vol me-1 mb-2">{producto.voltaje}</div>}
								{producto.rotacionSincronica &&
									<div className="badge bg-danger text-danger rot me-1 mb-2">{producto.rotacionSincronica}</div>}
								{producto.potencia &&
									<div className="badge bg-primary text-primary pot me-1 mb-2">{producto.potencia}</div>}
								{/* {producto.proteccion &&
									<div className="badge bg-primary text-secondary rot me-1 mb-2">{producto.proteccion}</div>}
								{producto.carcasa &&
									<div className="badge bg-primary text-info pot me-1 mb-2">{producto.carcasa}</div>}
								{producto.embalaje &&
									<div className="badge bg-primary text-primary pot me-1 mb-2">{producto.embalaje}</div>} */}
							</div>

							{pedido?.find(p => p.idProducto === producto.idProducto) ?
								<Button
									style={{ width: "100%", borderRadius: "50px" }}
									variant="outline-primary"
									onClick={() => handleRmoverProductoClick(producto.idProducto)}>
									Quitar del pedido
								</Button>
								:
								<Button
									style={{ width: "100%", borderRadius: "50px" }}
									onClick={() => handleAgregarProductoClick(producto.idProducto)}>
									Agregar al pedido
								</Button>}

						</div>

					</div>

				</div>

			</div >
		)
	}

	return (
		<div className="product-card card border br-12">
			<div className="card-header p-0 overflow-hidden" style={{ cursor: "pointer" }} onClick={handleProductoClick}>
				<div className="card-img-top position-relative bg-white"
					style={{ height: "350px" }}>
					<img src={producto.urlImagen} alt={producto.nombre} className="img-responsive w-100" />
				</div>
			</div>
			<div className="card-body">

				<h2 className="card-text fw-bold mb-3" style={{ cursor: "pointer" }} onClick={handleProductoClick}>
					{producto.nombre}
				</h2>

				<div className="description card-text mb-4">
					<p>{producto.descripcion}</p>
				</div>

				<div className="taxonomies mb-2">
					{producto.nombreTipoProductoAbuelo &&
						<div className="badge bg-success text-success cat me-1 mb-2">{producto.nombreTipoProductoAbuelo}</div>}
					{producto.nombreTipoProductoPadre &&
						<div className="badge bg-success text-success cat me-1 mb-2">{producto.nombreTipoProductoPadre}</div>}
					<div className="badge bg-success text-success cat me-1 mb-2">{producto.nombreTipoProducto}</div>
					{producto.voltaje &&
						<div className="badge bg-warning text-warning vol me-1 mb-2">{producto.voltaje}</div>}
					{producto.rotacionSincronica &&
						<div className="badge bg-danger text-danger rot me-1 mb-2">{producto.rotacionSincronica}</div>}
					{producto.potencia &&
						<div className="badge bg-primary text-primary pot me-1 mb-2">{producto.potencia}</div>}
					{/* {producto.proteccion &&
						<div className="badge bg-primary text-secondary rot me-1 mb-2">{producto.proteccion}</div>}
					{producto.carcasa &&
						<div className="badge bg-primary text-info pot me-1 mb-2">{producto.carcasa}</div>}
					{producto.embalaje &&
						<div className="badge bg-primary text-primary pot me-1 mb-2">{producto.embalaje}</div>} */}
				</div>

				{pedido?.find(p => p.idProducto === producto.idProducto) ?
					<Button
						style={{ width: "100%", borderRadius: "50px" }}
						variant="outline-primary"
						onClick={() => handleRmoverProductoClick(producto.idProducto)}>
						Quitar del pedido
					</Button>
					:
					<Button
						style={{ width: "100%", borderRadius: "50px" }}
						onClick={() => handleAgregarProductoClick(producto.idProducto)}>
						Agregar al pedido
					</Button>}

			</div>
		</div>
	);
};

export default ProductCard;