import React, { useState } from 'react'
import SetupTabsContainer from './setupTab/SetupTabsContainer'
import SetupSearchWrapper from './setupSearch/SetupSearchWrapper'
import { Routes as Switch, Route, useLocation, useParams, useNavigate, Outlet } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import { Button } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SetupPage = (props) => {
    const [prevSearchForm, setPrevSearchForm] = useState();
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const lastPart = location.pathname.split('/').filter(Boolean).pop();

    // Handlers
    const handleSelect = (id) => {
        if (id)
            navigate(`./${id}`);
    }

    const handleCreateClick = () => {
        navigate(`./new`);
    }

    const handleBack = () => {
        navigate(".");
    }

    const handleCreate = (id) => {
        navigate(`./${id}`);
    }

    const handleDelete = () => {
        navigate(".");
    }

    const handleSearch = (form) => {
        setPrevSearchForm(form);
    }

    // React
    const renderHeader = () => {
        const { title, icon, addNewTitle } = props.setupProps;

        return (
            <div style={{ display: "flex", marginLeft: "10px", marginTop: "7px", justifyContent: "space-between", alignItems: "center", color: "#2e599a" }}>
                <div style={{ display: "flex" }}>
                    {icon &&
                        <FontAwesomeIcon
                            style={{ fontSize: "30px", marginTop: "5px", marginLeft: "-10px" }}
                            icon={icon} />}
                    <div style={{ marginLeft: "10px", marginTop: "3px" }}>
                        <h3>{title}</h3>
                    </div>
                </div>
                {!params.id && lastPart !== "new" && addNewTitle &&
                    <Button
                        size="sm"
                        variant="success"
                        style={{ marginRight: "-9px" }}
                        onClick={handleCreateClick}>
                        {addNewTitle}
                    </Button>}
            </div>
        )
    }

    const renderRoutes = () => {
        return (
            <Switch>
                <Route
                    path="/"
                    element={
                        <SetupSearchWrapper
                            {...props}
                            onSelect={handleSelect}
                            onSearch={handleSearch}
                            prevSearchForm={prevSearchForm}
                        />
                    } />
                <Route
                    path="new"
                    element={
                        <SetupTabsContainer
                            adding
                            setupProps={props.setupProps}
                            onBack={handleBack}
                            onCreate={handleCreate}
                        />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <SetupTabsContainer
                            setupProps={props.setupProps}
                            onBack={handleBack}
                            onDelete={handleDelete}
                        />
                    }
                />
                <Route
                    path=":id/:tabKey"
                    element={
                        <SetupTabsContainer
                            url={params.url}
                            setupProps={props.setupProps}
                            onBack={handleBack}
                            onDelete={handleDelete}
                        />
                    }
                />
            </Switch>
        );
    }

    return (
        <div style={{ marginLeft: "15px", marginRight: "30px" }}>
            <Row>
                {renderHeader()}
            </Row>
            <Row>
                {renderRoutes()}
                <Outlet />
            </Row>
        </div>
    )
}

export default SetupPage;