import React, { useMemo, useRef } from 'react'
import SetupTab from 'modules/setup/setupTab/SetupTab'
import { TextBox, Label, RemoveModal } from 'components'
import { Row, Col, FormGroup } from 'react-bootstrap'
import { fetchUrl } from 'modules/util/functions'
import { useSetupTabValueContext, useSetupTabActionsContext } from 'modules/setup/setupTab/SetupTabContext'
import * as Urls from 'modules/Urls'
import { Link } from 'react-router-dom'

const TrabajoInfoTab = () => {
	const { selectedId, form, adding } = useSetupTabValueContext();
	const { onDelete, onError, onSuccess } = useSetupTabActionsContext();
	const _removeModal = useRef();

	const configuration = useMemo(() => ({
		urls: {
			get: "/trabajoSetup/getTrabajoInfo",
			save: "/trabajoSetup/saveTrabajoInfo",
			create: "/trabajoSetup/createTrabajo"
		},
		getRequest: () => {
			return {
				idTrabajo: selectedId
			}
		},
		saveRequest: (form) => {
			return {
				idTrabajo: selectedId,
				numero: form.numero
			}
		}
	}), [selectedId]);

	const actions = [{
		key: "delete",
		name: "Borrar",
		onClick: () => {
			handleDelete();
		}
	}];

	// Handlers
	const handleDelete = () => {
		const rq = {
			idTrabajo: selectedId
		};

		_removeModal.current.open({
			title: "Eliminando trabajo",
			name: form.numero,
			onYes: () => {
				fetchUrl("/trabajoSetup/deleteTrabajo", "post", rq, {
					success: (r) => {
						onSuccess(r.message);
						onDelete();
					},
					error: (r) => {
						onError(r.errorMessage);
					}
				}, { block: false });
			}
		});
	}

	console.log(form.idPresupuesto)

	return (
		<React.Fragment>
			<RemoveModal
				ref={_removeModal} />

			<SetupTab
				configuration={configuration}
				actions={actions}>
				<Row>
					<Col md={4}>
						<FormGroup>
							<Label required>Numero</Label>
							<TextBox
								name="numero"
								placeholder="Numero" />
						</FormGroup>
						{!adding &&
							<FormGroup style={{ marginTop: "20px" }}>
								<Label>Presupuesto</Label>
								<div>{form.idPresupuesto ?
									<Link to={`/${Urls.CRM}/${Urls.PresupuestoSetup}/${form.idPresupuesto}`}>{form.numeroPresupuesto}</Link>
									:
									"No tiene presupuesto"
								}</div>
							</FormGroup>}
					</Col>
				</Row>
			</SetupTab>
		</React.Fragment>
	)
}

export default TrabajoInfoTab;