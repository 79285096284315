import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'components';
import * as Urls from 'modules/Urls';

const Servicios = () => {
	document.title = "Nuestros servicios - Bal Solutions";

	const renderBanner = () => {
		return (
			<section className="banner servicios">

				<div className="px-0 container-fluid">

					<div className="row">

						<div className="content col-12">

							<h1 className="mb-4" style={{ fontWeight: "900" }}>
								Nuestros Servicios
							</h1>

							<div className="mb-4">
								El detalle de los trabajos que realizamos, es el siguiente:
							</div>

							<Link to={Urls.Contacto}>
								<Button
									variant="outline-secondary"
									style={{ color: "white", borderColor: "white", borderRadius: "50px" }}>
									Hablá con nosotros
								</Button>
							</Link>

						</div>

					</div>

				</div>

			</section>
		);
	}

	const renderItems = () => {
		const specs = [
			"Bobinado de motores eléctricos en general.",
			"Bobinado de bombas centrífugas y sumergibles.",
			"Bobinado de motores con freno.",
			"Reparaciones de máquinas de soldar.",
			"Reparaciones de cargadores de batería.",
			"Bobinado de transformadores.",
			"Diseño de tableros de potencia."
		];

		const specs2 = [
			"Bobinas para electroimanes. Encapsulados.",
			"Mantenimiento de máquinas eléctricas en planta.",
			"Reparación de transformadores potencia.",
			"Mantenimiento de transformadores In-situ.",
			"Cálculo y diseño de subestaciones transformadores de MT/BT.",
			"Cálculo de iluminación de plantas.",
			"Centro de control de motores (CCM)."
		];

		return (
			<section className="services specialization">

				<div className="container">

					<div className="row">

						<div className="title col-12">

							<h2>
								Además nos especializamos en:
							</h2>

						</div>

						<div className="list col-lg-6 col-md-12">

							<ul>
								{specs.map((item, index) => (
									<li key={index}>
										{item}
									</li>
								))}
							</ul>

						</div>

						<div className="list col-lg-6 col-md-12">

							<ul>
								{specs2.map((item, index) => (
									<li key={index}>
										{item}
									</li>
								))}
							</ul>

						</div>

					</div>

				</div>

			</section>
		)
	}

	return (
		<>
			{renderBanner()}
			{renderItems()}
		</>
	);
};

export default Servicios;