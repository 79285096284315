import React, { useState, useEffect } from 'react'
import { Combo } from 'components'
import { fetchUrl } from 'modules/util/functions'

const PedidoWebCombo = (props) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetchUrl("/query/getPedidosWeb", "get", {}, {
            success: (r) => {
                setItems(r.data.items.map(x => ({
                    value: x.idPedidoWeb,
                    label: x.label
                })));
            }
        }, { block: false });
    }, []);

    return (
        <Combo
            {...props}
            items={items} />
    )
}

export default PedidoWebCombo;

PedidoWebCombo.defaultProps = {
    formControl: true,
    formManaged: false
};