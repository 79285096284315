import React from 'react'
import { Link } from 'react-router-dom';
import * as Urls from 'modules/Urls'
import { useUserContext } from 'modules/contexts/UserContext';
import { usePedidoContext } from 'modules/contexts/PedidoContext'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import * as BrowserStorage from 'modules/BrowserStorage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "styles/theme.css";

const Header = () => {
	const { user, setUser } = useUserContext();
	const { pedido } = usePedidoContext();
	const logoUrl = `/images/logo.balsolutions.svg`;

	const handleLogout = () => {
		// Remove user from context
		setUser(null);

		// Remove user from local storage
		BrowserStorage.remove("user");
	}

	return (
		<div className="px-0 container-fluid">
			<nav className="bg-white flex-column navbar navbar-expand-lg navbar-light sticky-top" role="navigation">

				<div className="py-3 container">
					<Link to={Urls.Home}>
						<img src={logoUrl} alt="BALSOLUTIONS" className="logo-head" />
					</Link>


					<div className="d-flex justify-content-end">

						<div className="d-flex align-items-center d-md-none">

						</div>

						<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#basic-navbar-nav" aria-controls="basic-navbar-nav" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>

					</div>

					<div className="justify-content-end navbar-collapse collapse" id="basic-navbar-nav">

						<div className="navbar-nav">

							<Link to={Urls.Home} className="nav-link">
								INICIO
							</Link>

							<Link to={Urls.Nosotros} className="nav-link">
								NOSOTROS
							</Link>

							<Link to={Urls.Productos} className="nav-link">
								PRODUCTOS
							</Link>

							<Link to={Urls.Contacto} className="px-4 rounded-pill btn-blue nav-link">
								CONTACTO
							</Link>

							<Link to={Urls.Pedido} className="nav-link">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart" viewBox="0 0 16 16">
									<path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" fill="#000" />
								</svg> <span id="count_pedido">{pedido ? `(${pedido.length})` : ""}</span>
							</Link>

							<Link to={user ? Urls.CRM : Urls.Login} className="nav-link ps-0 ms-0 ps-0" style={{ paddingBottom: "-23px" }} title='Ingresar'>
								<i className="bi bi-box-arrow-in-right"></i>
							</Link>

							{user &&
								<>
									<div style={{ color: "#3737fa", marginTop: "8px" }}>
										<FontAwesomeIcon
											icon={faUser}
											style={{ marginRight: "5px" }} />
										{user.nombreUsuario}
									</div>
									<div
										style={{ cursor: "pointer", marginTop: "8px", marginLeft: "20px" }}
										onClick={handleLogout} >
										Cerrar sesion
									</div>
								</>}
						</div>

					</div>


				</div>

			</nav>
		</div>
	)
}

export default Header;