import React, { useState, useCallback, useEffect } from 'react'
import SetupList from './SetupList'
import { SetupSearchValueContext, SetupSearchActionsContext } from './SetupSearchContext'
import { fetchUrl } from 'modules/util/functions'
import { Box } from 'components'
import { Outlet } from 'react-router-dom'
// import * as Notifications from 'fw/modules/Notifications'

const SetupSearchWrapper = (props) => {
    const initialState = () => {
        return {
            form: props.prevSearchForm || {},
            rows: [],
            additionalInfo: null
        }
    }

    const [state, setState] = useState(initialState());

    const search = useCallback(() => {
        const rq = {
            ...state.form,
            ...state.sortOptions
        };

        fetchUrl(props.setupProps.searchUrl, "post", rq, {
            success: (r) => {
                setState(ps => ({
                    ...ps,
                    rows: r.data.items,
                    additionalInfo: r.data.additionalInfo
                }));
            },
            error: (r) => {
                // Notifications.error(r.errorMessage);
            }
        });
    }, [state.form, state.sortOptions, props.setupProps.searchUrl]);

    useEffect(() => {
        search();
    }, [search]);

    // Handlers
    const handleFormChange = (form) => {
        setState(ps => ({
            ...ps,
            form
        }));

        props.onSearch(form);
    }

    const handleReset = () => {
        setState(ps => ({
            ...ps,
            form: {}
        }));

        props.onSearch({});
        search();
    }

    const handleSubmit = () => {
        props.onSearch({});
        search();
    }

    const handleGridSort = (sortColumn, sortDirection) => {
        setState(ps => ({
            ...ps,
            sortOptions: {
                sortColumn,
                sortDirection
            }
        }));

        search();
    }

    const values = {
        form: state.form,
        rows: state.rows
    };

    const actions = {
        onFormChange: handleFormChange,
        onReset: handleReset,
        onSubmit: handleSubmit,
        onSearch: search
    };

    const Search = props.search;
    const AdditionalInfo = props.additionalInfo;

    return (
        <React.Fragment>
            <Box.Box>
                <Box.Header>
                    <SetupSearchValueContext.Provider value={values}>
                        <SetupSearchActionsContext.Provider value={actions}>
                            <Search />
                        </SetupSearchActionsContext.Provider>
                    </SetupSearchValueContext.Provider>
                </Box.Header>
                <Box.Body>
                    <SetupList
                        columns={props.setupProps.columns}
                        rowKey={props.setupProps.rowKey}
                        rows={state.rows}
                        onGridSort={handleGridSort}
                        onSelect={props.onSelect} />
                </Box.Body>
                {AdditionalInfo &&
                    <div style={{ marginTop: "10px" }}>
                        <AdditionalInfo
                            {...state.additionalInfo} />
                    </div>}
            </Box.Box>
            <Outlet />
        </React.Fragment>
    )
}

export default SetupSearchWrapper;