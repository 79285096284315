import React, { useEffect, useState, useRef } from "react";
import { usePedidoContext } from 'modules/contexts/PedidoContext'
import { Link } from "react-router-dom";
import { fetchUrl } from 'modules/util/functions'
import * as BrowserStorage from 'modules/BrowserStorage'
import * as Urls from 'modules/Urls'
import { Button } from "components";

const Pedido = () => {
	const { setPedido, pedido } = usePedidoContext();
	const [productos, setProductos] = useState([]);
	const pedidoRef = useRef(pedido);
	document.title = "Pedido - Bal Solutions";

	useEffect(() => {
		if (!pedidoRef.current || pedidoRef.current.length === 0)
			return;

		fetchUrl("/query/getProductos", "post", { idProductos: pedidoRef.current.map(p => p.idProducto) }, {
			success: (r) => {
				const prods = r.data.productos.map(p => {
					p.cantidad = pedidoRef.current.find(pp => pp.idProducto === p.idProducto).cantidad;
					return p;
				});

				setProductos(prods);
			}
		});
	}, []);

	const handleRmoverProductoClick = (idProducto) => {
		setPedido((pedido) => {
			BrowserStorage.set("pedido", pedido.filter(p => p.idProducto !== idProducto));
			return pedido.filter(p => p.idProducto !== idProducto);
		});

		setProductos(productos.filter(p => p.idProducto !== idProducto));
	}

	const handleAgregar = (idProducto) => {
		setPedido((pedido) => {
			const p = pedido.find(p => p.idProducto === idProducto);
			p.cantidad += 1;

			BrowserStorage.set("pedido", pedido);

			return pedido;
		});

		setProductos(productos.map(p => {
			if (p.idProducto === idProducto)
				p.cantidad += 1;

			return p;
		}));
	}

	const handleRemover = (idProducto) => {
		setPedido((pedido) => {
			const p = pedido.find(p => p.idProducto === idProducto);
			p.cantidad -= 1;

			BrowserStorage.set("pedido", pedido);

			return pedido;
		});

		setProductos(productos.map(p => {
			if (p.idProducto === idProducto)
				p.cantidad -= 1;

			return p;
		}));
	}

	if (!pedido || pedido.length === 0) {
		return (
			<section className="cart">

				<div className="container">

					<div className="row justify-content-center">
						<div className="container">
							<div className="row text-center">
								<p>
									No tienes productos en tu orden de pedido.
								</p>
								<p>
									Puedes elegirlos <Link to="/productos"> Aquí <i className="bi bi-arrow-right"></i></Link>
								</p>
							</div>
						</div>
					</div>
				</div>

			</section>
		)
	}

	return (
		<section className="cart">

			<div className="container">

				<div className="row justify-content-center">

					<div className="products-table col-12">

						<div className="table-responsive">

							<form action="<?php echo BALSOLUTIONS_URL; ?>/verificar/" method="post">

								<table className="table mb-4">

									<thead>
										<tr>
											<th className="shop-titles">Producto</th>
											<th className="shop-titles quantity">Cantidad</th>
											<th className="shop-titles"></th>
										</tr>
									</thead>

									<tbody>

										{productos?.map(producto => (
											<tr id="post-pedido-<?php echo $post_id; ?>">

												<td className="product">

													<div className="d-flex align-items-center">
														<img src={producto.urlImagen} alt="" className="img-responsive w-100" />
													</div>

													<Link blank to={Urls.Productos + "/" + producto.idProducto} className="text-decoration-none">
														{producto.nombre}
													</Link>

												</td>

												<td>
													<div className="input-group number-input">

														<div className="input-group-prepend">
															<button disabled={producto.cantidad === 0} className="btn btn-outline-secondary minus" type="button" style={{ width: "40px", borderRadius: "5px 0px 0px 5px" }} onClick={() => handleRemover(producto.idProducto)}>-</button>
														</div>

														<input type="text" className="form-control mb-0" value={producto.cantidad} />

														<div className="input-group-append">
															<button className="btn btn-outline-secondary plus" type="button" style={{ width: "40px", borderRadius: "0px 5px 5px 0px" }} onClick={() => handleAgregar(producto.idProducto)}>+</button>
														</div>

													</div>
												</td>

												<td style={{ textAlign: "right" }}>

													<Button
														variant="outline-primary"
														style={{ borderRadius: "50px" }}
														onClick={() => handleRmoverProductoClick(producto.idProducto)} >
														Quitar del pedido
													</Button>

												</td>

											</tr>
										))}

									</tbody>

								</table>

								<div className="buttons d-flex justify-content-center justify-content-md-end">

									<Link to={Urls.Productos} className="rounded-pill btn btn-outline-primary">
										Seguir eligiendo
									</Link>&nbsp;

									<Link to={Urls.Verificar}>
										<button tabindex="0" type="submit" className="rounded-pill btn btn-primary">
											Finalizar pedido
										</button>
									</Link>

								</div>

							</form>

						</div>

					</div>

				</div>

			</div>

		</section>
	)
};

export default Pedido;