import React, { useMemo, useRef } from 'react'
import SetupTab from 'modules/setup/setupTab/SetupTab'
import { TextBox, TextArea, Label, RemoveModal } from 'components'
import { Row, Col, FormGroup } from 'react-bootstrap'
import { fetchUrl } from 'modules/util/functions'
import { useSetupTabValueContext, useSetupTabActionsContext } from 'modules/setup/setupTab/SetupTabContext'

const ClienteInfoTab = () => {
    const { selectedId, form } = useSetupTabValueContext();
    const { onDelete, onError, onSuccess } = useSetupTabActionsContext();
    const _removeModal = useRef();

    const configuration = useMemo(() => ({
        urls: {
            get: "/cliente/getClienteInfo",
            save: "/cliente/saveClienteInfo",
            create: "/cliente/createCliente"
        },
        getRequest: () => {
            return {
                idCliente: selectedId
            }
        },
        saveRequest: (form) => {
            return {
                idCliente: selectedId,
                nombre: form.nombre,
                cuit: form.cuit,
                direccion: form.direccion,
                pagaEnDias: form.pagaEnDias,
                otros: form.otros
            }
        }
    }), [selectedId]);

    const actions = [
        {
            key: "delete",
            name: "Borrar",
            onClick: () => {
                handleDelete();
            }
        }
    ];

    // Handlers
    const handleDelete = () => {
        const rq = {
            idCliente: selectedId
        };

        _removeModal.current.open({
            title: "Eliminando cliente",
            name: form.nombre,
            onYes: () => {
                fetchUrl("/cliente/deleteCliente", "post", rq, {
                    success: (r) => {
                        onSuccess(r.message);
                        onDelete();
                    },
                    error: (r) => {
                        onError(r.errorMessage);
                    }
                }, { block: false });
            }
        });
    }

    return (
        <React.Fragment>
            <RemoveModal
                ref={_removeModal} />

            <SetupTab
                configuration={configuration}
                actions={actions}>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <Label required>Nombre de cliente</Label>
                            <TextBox
                                name="nombre"
                                placeholder="Nombre" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Cuit</Label>
                            <TextBox
                                name="cuit"
                                placeholder="CUIT" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Direccion</Label>
                            <TextBox
                                name="direccion"
                                placeholder="Direccion" />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label>Paga en dias</Label>
                            <TextBox
                                name="pagaEnDias"
                                placeholder="Paga en dias" />
                        </FormGroup>
                        <FormGroup>
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Otros</Label>
                            <TextArea
                                name="otros"
                                placeholder="Otros"
                                style={{ height: "117px" }} />
                        </FormGroup>
                    </Col>
                </Row>
            </SetupTab>
        </React.Fragment>
    )
}

export default ClienteInfoTab;