import Header from 'crm/layout/Header';
import React from 'react'
import { Outlet } from 'react-router-dom';

const Dashboard = () => {
	document.title = "CRM - Bal Solutions";

	return (
		<div style={{ backgroundColor: "#d2d6de" }}>
			<Header />
			<Outlet />
		</div>
	)
}

export default Dashboard;