import React, { useEffect, useState } from "react";
import { Button } from 'components';
import { useParams } from "react-router-dom";
import { fetchUrl } from 'modules/util/functions'
import { usePedidoContext } from 'modules/contexts/PedidoContext'
import * as BrowserStorage from 'modules/BrowserStorage'

const Producto = () => {
	const [producto, setProducto] = useState({});
	const { id } = useParams();
	const { setPedido, pedido } = usePedidoContext();
	document.title = producto.nombre ? producto.nombre + " - Bal Solutions" : "Producto - Bal Solutions";

	useEffect(() => {
		fetchUrl("/query/getProducto", "get", { idProducto: id }, {
			success: (r) => {
				setProducto(r.data);
			}
		}, { block: false });
	}, [id]);

	const handleAgregarProductoClick = (idProducto) => {
		setPedido((pedido) => {
			if (!pedido) {
				BrowserStorage.set("pedido", [{ idProducto, cantidad: 1 }]);
				return [{ idProducto, cantidad: 1 }];
			}

			BrowserStorage.set("pedido", [...pedido, { idProducto, cantidad: 1 }]);
			return [...pedido, { idProducto, cantidad: 1 }];
		});
	}

	const handleRmoverProductoClick = (idProducto) => {
		setPedido((pedido) => {
			BrowserStorage.set("pedido", pedido.filter(p => p.idProducto !== idProducto));
			return pedido.filter(p => p.idProducto !== idProducto);
		});
	}

	if (!producto)
		return <div>Producto no encontrado.</div>;

	return (
		<section className="single-product description pb-5">

			<div className="container">

				<div className="row">

					<div className="title col-12">

						<h1>
							{producto.nombre}
						</h1>

					</div>

					<div className="image col-12 col-md-6 col-lg-5">

						<div className="d-flex align-items-center position-relative">

							<img src={producto.urlImagen} alt={producto.nombre} className="img-responsive w-100" />

							<div className="taxonomies mb-2 position-absolute" style={{ top: "20px", left: "20px" }}>

								{producto.nombreTipoProductoAbuelo &&
									<div className="badge bg-success text-success bold cat me-1 mb-2 shadow">
										{producto.nombreTipoProductoAbuelo}
									</div>}

								{producto.nombreTipoProductoPadre &&
									<div className="badge bg-success text-success bold cat me-1 mb-2 shadow">
										{producto.nombreTipoProductoPadre}
									</div>}

								{producto.nombreTipoProducto &&
									<div className="badge bg-success text-success bold cat me-1 mb-2 shadow">
										{producto.nombreTipoProducto}
									</div>}
							</div>


						</div>

					</div>

					<div className="content col-lg-7 col-md-12 d-flex align-items-center">

						<div>

							<div className="row">

								<div className="title col-12">

									<h3>
										Descripción del producto
									</h3>

									<p>
										{producto.descripcion}
									</p>

								</div>

							</div>

							{pedido?.find(p => p.idProducto === producto.idProducto) ?
								<Button
									style={{ width: "200px", borderRadius: "50px" }}
									variant="outline-primary"
									onClick={() => handleRmoverProductoClick(producto.idProducto)}>
									Quitar del pedido
								</Button>
								:
								<Button
									style={{ width: "200px", borderRadius: "50px" }}
									onClick={() => handleAgregarProductoClick(producto.idProducto)}>
									Agregar al pedido
								</Button>}

						</div>



					</div>

				</div>

			</div>

		</section>
	)
};

export default Producto;