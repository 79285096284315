import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Form } from 'react-bootstrap'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

export default function Label(props) {

	return (
		<Form.Label
			style={{ fontWeight: "bold" }}>
			{props.children} {props.required && <span style={{ color: "red", fontWeight: "bold" }}>*</span>}
			{props.help &&
				<OverlayTrigger
					overlay={<Tooltip>{props.help}</Tooltip>}>
					<FontAwesomeIcon
						icon={faInfoCircle}
						style={{ color: "green", marginLeft: props.required && "5px" }} />
				</OverlayTrigger>}
		</Form.Label>
	)
}