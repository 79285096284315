import React from 'react'
import Search from './Search'
import SetupPage from 'modules/setup/SetupPage'
import TrabajoInfoTab from './trabajoInfo/TrabajoInfoTab'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

const TrabajoSetup = () => {

	const setupProps = {
		title: "Trabajos",
		icon: faSquare,
		addNewTitle: "Crear trabajo",
		searchUrl: "/trabajoSetup/search",
		rowKey: "idTrabajo",
		columns: [
			{ key: "idTrabajo", name: "ID", sortable: true, width: 60 },
			{ key: "numero", name: "Numero", sortable: true },
		],
		tabs: [
			{ key: "info", title: "Info", comp: TrabajoInfoTab }
		]
	};

	return (
		<SetupPage
			setupProps={setupProps}
			search={Search} />
	)
}

export default TrabajoSetup;