import React from 'react'
import ReactDataGrid from 'react-data-grid'

// import 'react-data-grid/lib/styles.css';

const SetupList = (props) => {
    // Handlers
    const handleRowClick = (index, row, col) => {
        if (index < 0)
            return;

        props.onSelect(row[props.rowKey]);
    }

    const handleGridSort = (sortColumn, sortDirection) => {
        const columnName = sortColumn[0].toUpperCase() + sortColumn.slice(1);

        props.onGridSort(columnName, sortDirection);
    }

    // React
    const columns = props.columns.map(x => ({
        ...x,
        resizable: true
    }));

    return (
        <div>
            <ReactDataGrid
                onRowClick={handleRowClick}
                onGridSort={handleGridSort}
                rowKey={props.rowKey}
                columns={columns}
                rowGetter={i => props.rows[i]}
                rowsCount={props.rows?.length}
                enableCellSelect={false}
                minHeight={450} />
        </div>
        // <DataGrid
        //     onCellClick={handleRowClick}
        //     onSortColumnsChange={handleGridSort}
        //     rowKey={props.rowKey}
        //     columns={columns}
        //     rows={props.rows}
        //     rowsCount={props.rows?.length}
        //     enableCellSelect={false}
        //     style={{ height: "500px", width: "100%" }} />
    )
}

export default SetupList;