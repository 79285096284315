import React, { useState } from 'react'
import * as BrowserStorage from 'modules/BrowserStorage'
import { usePedidoContext } from 'modules/contexts/PedidoContext'
import { fetchUrl } from 'modules/util/functions'
import { Button } from 'components'
import * as Urls from 'modules/Urls'
import { useNavigate } from 'react-router-dom'

const errorCodes = {
	NOMBRE_REQUERIDO: "NOMBRE_REQUERIDO",
	APELLIDO_REQUERIDO: "APELLIDO_REQUERIDO",
	EMAIL_REQUERIDO: "EMAIL_REQUERIDO",
	TELEFONO_REQUERIDO: "TELEFONO_REQUERIDO",
	MEDIO_DE_PAGO: "MEDIO_DE_PAGO"
};

const Verificar = () => {
	const { setPedido, pedido } = usePedidoContext();
	const [form, setForm] = useState({});
	const [errors, setErrors] = useState([]);
	const navigate = useNavigate();
	document.title = "Verificar - Bal Solutions";

	const handleFormChange = (change) => {
		setForm({ ...form, ...change });
	}

	const handleConfirm = () => {
		const errors = [];

		if (!form.nombre) {
			errors.push({
				errorCode: errorCodes.NOMBRE_REQUERIDO,
				errorMessage: "El nombre es requerido"
			});
		}

		if (!form.apellido) {
			errors.push({
				errorCode: errorCodes.APELLIDO_REQUERIDO,
				errorMessage: "El apellido es requerido"
			});
		}

		if (!form.email) {
			errors.push({
				errorCode: errorCodes.EMAIL_REQUERIDO,
				errorMessage: "El email es requerido"
			});
		}

		if (!form.telefono) {
			errors.push({
				errorCode: errorCodes.TELEFONO_REQUERIDO,
				errorMessage: "El telefono es requerido"
			});
		}

		if (!form.medioPago) {
			errors.push({
				errorCode: errorCodes.MEDIO_DE_PAGO,
				errorMessage: "El medio de pago es requerido"
			});
		}

		if (!errors.length) {
			const rq = {
				nombre: form.nombre,
				apellido: form.apellido,
				cuit: form.cuit,
				direccionCalle: form.direccion,
				codigoPostal: form.codigoPostal,
				ciudad: form.ciudad,
				provincia: form.provincia,
				email: form.email,
				telefono: form.telefono,
				medioDePago: form.medioPago,
				empresa: form.empresa,
				productos: pedido.map(p => ({ idProducto: p.idProducto, cantidad: p.cantidad }))
			};

			fetchUrl("/pedidoWeb/confirmarPedidoWeb", "post", rq, {
				success: (r) => {
					navigate(`${Urls.Pedido}/${r.data.entityId}`);

					BrowserStorage.set("pedido", []);
					setPedido([]);
				}
			}, { block: true });
		} else
			setErrors(errors);
	}

	return (
		<section class="checkout">

			<div class="container">

				<div class="row">

					<div class="form col-lg-9 col-md-12 position-relative">

						<p class="shop-titles title">
							Detalles de facturación
						</p>

						<div class="row">

							<div class="col-12">
								<input
									placeholder="CUIT"
									type="text"
									class="form-control cuit"
									value={form.cuit}
									onChange={(e) => handleFormChange({ cuit: e.target.value })} />
							</div>

							<div class="pe-0 pe-md-2 col-lg-6 col-md-12">
								<input
									placeholder="Nombre"
									type="text"
									class="form-control text"
									value={form.nombre}
									onChange={(e) => handleFormChange({ nombre: e.target.value })} />
								{errors.some(x => x.errorCode === errorCodes.NOMBRE_REQUERIDO) && (
									<div style={{ marginLeft: "10px", marginBottom: "10px", marginTop: "-10px" }} className="text-danger">{errors.find(x => x.errorCode === errorCodes.NOMBRE_REQUERIDO).errorMessage}</div>
								)}
							</div>

							<div class="ps-0 ps-md-2 col-lg-6 col-md-12">
								<input
									placeholder="Apellido"
									type="text"
									class="form-control text"
									value={form.apellido}
									onChange={(e) => handleFormChange({ apellido: e.target.value })} />
								{errors.some(x => x.errorCode === errorCodes.APELLIDO_REQUERIDO) && (
									<div style={{ marginLeft: "10px", marginBottom: "10px", marginTop: "-10px" }} className="text-danger">{errors.find(x => x.errorCode === errorCodes.APELLIDO_REQUERIDO).errorMessage}</div>
								)}
							</div>

							<div class="col-12">
								<input
									placeholder="Nombre de la empresa"
									type="text"
									class="form-control"
									value={form.empresa}
									onChange={(e) => handleFormChange({ empresa: e.target.value })} />
							</div>

						</div>

						<p class="shop-titles">
							País / Región Argentina
						</p>

						<div class="row">

							<div class="pe-0 pe-md-2 col-lg-6 col-md-12">
								<input
									placeholder="Dirección de calle"
									type="text"
									class="form-control"
									value={form.direccion}
									onChange={(e) => handleFormChange({ direccion: e.target.value })} />
							</div>

							<div class="ps-0 ps-md-2 col-lg-6 col-md-12">
								<input
									placeholder="Código Postal"
									type="text"
									class="form-control"
									value={form.codigoPostal}
									onChange={(e) => handleFormChange({ codigoPostal: e.target.value })} />
							</div>

							<div class="col-12">
								<input
									placeholder="Ciudad"
									type="text"
									class="form-control text"
									value={form.ciudad}
									onChange={(e) => handleFormChange({ ciudad: e.target.value })} />
							</div>

							<div class="col-12">
								<input
									placeholder="Región/Provincia"
									type="text"
									class="form-control text"
									value={form.provincia}
									onChange={(e) => handleFormChange({ provincia: e.target.value })} />
							</div>

							<div class="pe-0 pe-md-2 col-lg-6 col-md-12">
								<input
									placeholder="Email"
									type="email"
									class="form-control email"
									value={form.email}
									onChange={(e) => handleFormChange({ email: e.target.value })} />
								{errors.some(x => x.errorCode === errorCodes.EMAIL_REQUERIDO) && (
									<div style={{ marginLeft: "10px", marginBottom: "10px", marginTop: "-10px" }} className="text-danger">{errors.find(x => x.errorCode === errorCodes.EMAIL_REQUERIDO).errorMessage}</div>
								)}
							</div>

							<div class="ps-0 ps-md-2 col-lg-6 col-md-12">
								<input
									placeholder="Teléfono"
									type="text"
									class="form-control tel"
									value={form.telefono}
									onChange={(e) => handleFormChange({ telefono: e.target.value })} />
								{errors.some(x => x.errorCode === errorCodes.TELEFONO_REQUERIDO) && (
									<div style={{ marginLeft: "10px", marginBottom: "10px", marginTop: "-10px" }} className="text-danger">{errors.find(x => x.errorCode === errorCodes.TELEFONO_REQUERIDO).errorMessage}</div>
								)}
							</div>

						</div>


					</div>

					<div class="checkout-summary col-lg-3 col-md-12">

						<div class="order-summary">

							<div class="payment pb-3">

								<p class="shop-titles title">
									Medio de pago
								</p>

								<div class="form-check">

									<input
										name="payment"
										type="radio"
										class="form-check-input"
										onChange={() => handleFormChange({ medioPago: "Transferencia" })} />

									<label class="form-check-label">
										Transferencia bancaria
									</label>

								</div>

								<div class="form-check">

									<input
										name="payment"
										type="radio"
										class="form-check-input"
										onChange={() => handleFormChange({ medioPago: "Cheque" })} />

									<label class="form-check-label">
										Cheque
									</label>

								</div>

								<div class="form-check">

									<input
										name="payment"
										type="radio"
										class="form-check-input"
										onChange={() => handleFormChange({ medioPago: "E-cheq" })} />

									<label class="form-check-label">
										E-cheq
									</label>

								</div>

								{errors.some(x => x.errorCode === errorCodes.MEDIO_DE_PAGO) && (
									<div style={{ marginLeft: "10px", marginBottom: "10px" }} className="text-danger">{errors.find(x => x.errorCode === errorCodes.MEDIO_DE_PAGO).errorMessage}</div>
								)}

							</div>

							<Button
								style={{ borderRadius: "50px" }}
								onClick={handleConfirm}>
								Realizar el pedido
							</Button>

						</div>

					</div>

				</div>

			</div>

		</section>
	)
}

export default Verificar;