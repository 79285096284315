import React from 'react'
import { ContactForm } from "components";

const Contacto = () => {
	document.title = "Hablá con nosotros - Bal Solutions";

	const renderBanner = () => {
		return (
			<section className="banner contacto" >

				<div className="px-0 container-fluid">

					<div className="row">

						<div className="content col-12">

							<h1 style={{ fontWeight: "900" }}>
								Hablá con nosotros
							</h1>

							Avenida Provincia de Buenos Aire 450, Parque Industrial Comirsa sector PyMES II, Ramallo, Buenos Aires.

							0336-154630781 / 0336-154630782

						</div>

					</div>

				</div>

			</section>
		)
	}

	const renderContactForm = () => {
		const imagen_contacto = "/images/2.jpg";

		return (
			<section className="contact-us">

				<div className="container">

					<div className="row">

						<div className="title col-12">

							<h2>
								Contacto
							</h2>

						</div>

						<div className="form col-lg-5 col-md-12">

							<p>
								Consultanos sobre lo que necesites y te responderemos a la brevedad.
							</p>

							<ContactForm />

						</div>

						<div className="image col-lg-7 col-md-12">
							<img src={imagen_contacto} alt="Contacto" width="100%" />
						</div>

					</div>

				</div>

			</section>
		)
	}

	const renderMap = () => {
		return (
			<section className="home map">
				<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2809.094996550697!2d-60.148417123351344!3d-33.398171477784395!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b75ee8cab96da7%3A0x7105c1cfb75d1543!2sBalsolutions!5e0!3m2!1sen!2sar!4v1718820838783!5m2!1sen!2sar" width="100%" height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="map" style={{ border: "0px" }}></iframe>
			</section>
		)
	}

	return (
		<>
			{renderBanner()}
			{renderContactForm()}
			{renderMap()}
		</>
	)
}

export default Contacto;