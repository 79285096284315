import React, { useMemo } from 'react'
import SetupTab from 'modules/setup/setupTab/SetupTab'
import { Table, Panel, ContactoCombo } from 'components'
import { Row, Col } from 'react-bootstrap'
import { useSetupTabValueContext, useSetupTabActionsContext } from 'modules/setup/setupTab/SetupTabContext'

const ContactosTab = () => {
    const { selectedId, form } = useSetupTabValueContext();
    const { onFormChange } = useSetupTabActionsContext();

    const configuration = useMemo(() => ({
        urls: {
            get: "/cliente/getContactos",
            save: "/cliente/saveContactos"
        },
        getRequest: () => {
            return {
                idCliente: selectedId
            }
        },
        saveRequest: (form) => {
            return {
                idCliente: selectedId,
                idContactos: form.contactos.map(x => x.idContacto)
            }
        }
    }), [selectedId]);

    const getColumns = () => {
        return [
            { key: "nombre", name: "Nombre" },
            { key: "tipo", name: "Tipo" },
            { key: "telefono", name: "Telefono" }
        ]
    }

    const handleAddContacto = (v) => {
        if (!v)
            return;

        onFormChange({
            ...form,
            contactos: [
                ...form.contactos,
                {
                    idContacto: v.value,
                    nombre: v.label,
                    telefono: v.telefono,
                    tipo: v.tipo
                }
            ]
        });
    }

    return (
        <SetupTab
            configuration={configuration}>
            <Row>
                <Col md={6}>
                    <Panel.Panel variant="info">
                        <Panel.Header>
                            Contactos
                        </Panel.Header>
                        <Panel.Body>
                            <Table
                                name="contactos"
                                columns={getColumns()}
                                enableRemove
                                noItemsLegend="No hay contactos asociados." />
                        </Panel.Body>
                    </Panel.Panel>
                </Col>
                <Col md={4}>
                    <Panel.Panel variant="info">
                        <Panel.Header>
                            Agregar contacto
                        </Panel.Header>
                        <Panel.Body>
                            <ContactoCombo
                                onChange={handleAddContacto}
                                exclusions={form.contactos?.map(x => x.idContacto)} />
                        </Panel.Body>
                    </Panel.Panel>
                </Col>
            </Row>
        </SetupTab>
    )
}

export default ContactosTab;